export const FaqMockData = [
  {
    id: 1,
    question: "What is Borrowing Capital/Loans and how it works?",
    data: {
      type: "para",
      answer: [
        "Loans are a common form of borrowing capital, where a borrower (in our case we the S & S Investments) receives a specified amount of money from a lender/investor/clients and agrees to repay the amount over a defined period with interest. Borrowing Capital/Loans can be obtained from banks, financial institutions, or private lenders. It comes with specific terms, interest rates, and repayment schedules agreed upon by the borrower and lender.",
      ],
    },
  },
  {
    id: 2,
    question:
      "Where the borrowed capital is used and invested by S & S Investments?",
    data: {
      answer: [
        "The specific use and investment of borrowed capital by S & S Investments would depend on the company's business model, investment strategy, and objectives. S & S Investments could be involved in various sectors and industries, and the capital borrowed might be used for a range of purposes, including:",
      ],
      section: [
        { sectionTitle: "" },
        {
          title: "Real Estate:",
          content: [
            "The company might invest in real estate properties, either for rental income or capital gains, using borrowed funds for property acquisitions or developments.",
          ],
        },
        {
          title: "Stock Market Investments:",
          content: [
            "S & S Investments might use borrowed capital to invest in stocks, bonds, and other financial instruments to generate returns through capital appreciation and dividend income.",
          ],
        },
        {
          title: "Bonds and Fixed Income:",
          content: [
            "Borrowed capital could be used to invest in corporate bonds, government securities, policies, debentures, new funds or other fixed-income instruments.",
          ],
        },
        {
          title: "Trading and Investment Management:",
          content: [
            "S & S Investments involved in trading securities, Portfolio Management Services, or operating investment funds. In this case, borrowed capital can be used to increase the scale of investments and potentially earn higher returns.",
          ],
        },
        {
          title: "Diversification:",
          content: [
            "S & S Investments may use borrowed capital to diversify its investment portfolio to spread risk across different asset classes.",
            "The specific investment choices and strategies would depend on the company's goals, risk tolerance, market conditions, and various other factors.",
          ],
        },
      ],
    },
  },
  {
    id: 3,
    question:
      "What are the different Plans & services available for Lending capital/Investments?",
    data: {
      type: "para",
      answer: [
        "You can find all the detailed information of our Plans & services from the Menu “Plans & Services”, new plans and offers will be displayed and notified within the same section.",
      ],
    },
  },

  {
    id: 4,
    question: "How to select a PLAN for Lending Capital/investments?",
    data: {
      type: "para",
      answer: [
        "After knowing and understanding all the details of our various plans well, you just need to fill the form with proper details and we will contact you to guide and help you to select proper plan.",
      ],
    },
  },
  {
    id: 5,
    question: "What will be the Next step after finalizing the Plans?",
    data: {
      type: "para",
      answer: [
        "After finalizing the Plan, and getting all your basic contact information, we will share the Bank Details of S & S Investments with you on your registered Email-ID and Contact Number, once the Amount of selected plan is Reflected in the S & S Investments Account, your plan will be activated and we will also send your credentials details on registered Email-ID to login and view your plan.",
      ],
    },
  },
  // list
  {
    id: 6,
    question: "Which Documents we need to keep ready?",
    data: {
      items: [
        "Aadhar Card [Both Side](SOFT COPY)[IN CLEAR and READABLE form]",
        "PAN Card(SOFT COPY) [IN CLEAR and READABLE form]",
        "YOUR COLOR PHOTO (SOFT COPY) [IN CLEAR form]",
        "CHEQUES/UPI/NETBANKING/IMPS/NEFT/RTGS/ONLINE PAYMENTS (SOFT COPY/SCREENSHOT) of Completed Payment. [IN CLEAR and READABLE form]",
        "Bank Details in which the Principal and Return Amount should be Credited/Reflected. (Bank Name, Account Name, Account Number ,Bank branch, and IFSC should be same from which the Principal Investment Transaction is Done or Completed Payment) [IN CLEAR and READABLE form]",
      ],
      style: "unordered",
      type: "list",
    },
  },
  {
    id: 7,
    question: "Where we can view our plan details?",
    data: {
      type: "para",
      answer: [
        "You can view the detailed information of your selected plan with other personal details by Logging In to your profile dashboard, where you can view Plan Details, Pay-out Details, View/Download Receipts, Personal Details, Plan Status, and Bank Details provided by you at the time of finalizing the plan.",
      ],
    },
  },
  {
    id: 8,
    question: "Is there any hidden cost or any registration cost?",
    data: {
      type: "para",
      answer: ["Not at all, there is no hidden or any registration charges."],
    },
  },
  {
    id: 9,
    question: "How the duration of plan is calculated?",
    data: {
      type: "para",
      answer: [
        "The Duration is the Number of DAYS between Plan START Date and Plan END Date/Maturity Date of Plan. ",
      ],
    },
  },
  {
    id: 10,
    question: "What is Plan START Date?",
    data: {
      type: "para",
      answer: [
        "Plan START Date is the date on which the selected plan’s Amount is credited/Reflected in S & S Investments Account.",
      ],
    },
  },
  {
    id: 11,
    question: "What is Plan END Date?",
    data: {
      type: "para",
      answer: [
        "Plan END Date also known as Maturity Date of Plan is the date on which the Principal Amount and the FINAL Interest will be credited/Reflected in Lender’s/Clients Account. [Depends on the Plan Selected].",
      ],
    },
  },
  {
    id: 12,
    question: "How Long Is a Quarter in the Financial Calendar?",
    data: {
      type: "para",
      answer: [
        "The specific months that make up a quarter can vary depending on the company's fiscal year structure. However, in S & S Investments , the standard calendar months are used:",
        "1st Quarter (Q1): January, February, March ",
        "2nd Quarter (Q2): April, May, June",
        "3rd Quarter (Q3): July, August, September",
        "4th Quarter (Q4): October, November, December",
      ],
    },
  },
  {
    id: 13,
    question: "How Maturity Date of Plan/END Date is calculated?",
    data: {
      answer: [
        "Maturity Date of Plan/END Date is calculated on QUATERLY Basis.",
      ],
      section: [
        { title: "Let’s Understand it with 3 different Scenario’s as:" },
        {
          title: "Scenario 1:-",
          content: [
            "Client 1 has selected PLATINUM Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-08-2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 3 (THREE) Years, Then the Maturity Date/ Plan’s END Date is Calculated as 30-09-2024 (As the Plan’s START date i.e. [25-08-2021] falls under 3 rd QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 3 rd QUARTER which is 30-09-2024)",
          ],
        },
        {
          title: "Scenario 2:-",
          content: [
            "Client 2 has selected GOLD Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-11-2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 2 (TWO) Years, Then the Maturity Date/ Plan’s END Date is Calculated as 31-12-2023 (As the Plan’s START date i.e. [25-11-2021] falls under 4 th QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 4 th QUARTER which is 31-12-2023)",
          ],
        },
        {
          title: "Scenario 3:-",
          content: [
            "Client 3 has selected SILVER Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-02- 2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 1 (ONE) Year, Then the Maturity Date/ Plan’s END Date is Calculated as 31-03-2022 (As the Plan’s START date i.e. [25-02-2021] falls under 1 st QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 1 st QUARTER which is 31-03-2022)",
          ],
        },
      ],
    },
  },
  {
    id: 14,
    question:
      "How Interest Rate Amount is calculated? And how and when the Interest Amount and Maturity Amount credited in Lender’s/Investors account?",
    data: {
      note: "CREDIT/REFLECTION of INTEREST or MATURITY AMOUNT may take 5 to 8 Additional WORKING DAYS of MENTIONED/PLANNED DATES.",
      answer: [
        "Interest Rates Amount is calculated on the basis of the plan selected and the duration associated with that particular plan, selected by the lenders/investors.",
      ],
      section: [
        { title: "Let’s Understand it with 3 different Scenario’s as:" },
        {
          title: "Scenario 1:-",
          content: [
            "Client 1 has selected SILVER Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-02-2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 1 (ONE) Year, and the INTEREST RATE for this particular plan is 8(EIGHT)% PER ANNUM/YEAR, The Maturity Date/ Plan’s END Date is Calculated as 31-03-2022 (As the Plan’s START date i.e. [25-02-2021] falls under 1 st QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 1 st QUARTER which is 31-03-2022) for calculating the INTEREST RATE Amount the Number of Days need to be counted. ",
            "Because this particular plan has Lock-in period of 1 Year, so the Interest Amounts will be credited only ONCE during the Plan’s Duration.",
            "As per this scenario the number of days are 400 days (Including Maturity/End date in calculation) and the INTEREST is calculated as 8% of 1,00,000/- for 400 Days will be 8767.12/- rounded to 8768/- and the Final Interest amount after 10% TDS Deduction is calculated as: 8768-876.8(10% TDS)=7,891.2/- Will be credited/Reflected Along with the Principal Amount in the lender’s Bank Account.",
          ],
        },
        {
          title: "Scenario 2:-",
          content: [
            "Client 2 has selected GOLD Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-11-2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 2 (TWO) Years, and the Interest RATE for this particular plan is 9.5(NINE.FIVE)% PER ANNUM/YEAR, The Maturity Date/ Plan’s END Date is Calculated as 31-12-2023 (As the Plan’s START date i.e. [25-11-2021] falls under 4 th QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 4 th QUARTER which is 31-12-2023) for calculating the Interest Amount the Number of Days need to be counted for per financial year.",
            "Because this particular plan has Lock-in period of 2 Years, so the Interest Amounts will be credited TWICE during the Plan’s Duration.",
            "As per this scenario the first year’s number of days will be calculated till the first financial year i.e. till 31-03-2022 and there are 127 days (Including end date of First Financial Year in calculation) and the Interest is calculated as 9.5% of 1,00,000/- for 127 Days will be 3,305.47/- rounded to 3,306/- and the Final Interest amount after 10% TDS Deduction is calculated as: 3,306-330.6(10% TDS)=2,975.4/- Will be credited/Reflected in the lender’s Bank Account.",
            "For the Second/Last Year, number of days will be calculated till the Maturity/End Date of plan i.e. till 31-12-2023 and there are 640 days (Including Maturity/End date Year in calculation) and the Interest is calculated as 9.5% of 1,00,000/- for 640 Days will be 16,657.53/- rounded to 16,658/- and the Final Interest amount after 10% TDS Deduction is calculated as: 16,658-1,665.8(10% TDS)=14,992.2/- Will be credited/Reflected Along with the Principal Amount in the lender’s Bank Account.",
          ],
        },
        {
          title: "Scenario 3:-",
          content: [
            "Client 3 has selected PLATINUM Plan and lended the capital of 1,00,000/- through Fixed Interest Rate Plan, and the Plan’s START Date is 25-08-2021 (the Date on which the selected Plan’s Amount is reflected in S & S Investments), Now this particular plan has Lock-in Period of 3 (THREE) Years, and the INTEREST RATE for this plan is 11(ELEVEN)% PER ANNUM/YEAR, The Maturity Date/ Plan’s END Date is Calculated as 30-09-2024 (As the Plan’s START date i.e. [25-11-2021] falls under 3 rd QUARTER ,so the Plan’s END/MATURITY Date will be LAST DATE of 3 rd QUARTER which is 30-09-2024) for calculating the Interest Amount the Number of Days need to be counted for per financial year.",
            "Because this particular plan has Lock-in period of 3 Years, so the Interest Amount will be credited THRICE during the Plan’s Duration.",
            "As per this scenario the first year’s number of days will be calculated till the first financial year end date i.e. till 31-03-2022 and there are 219 days (Including end date of First Financial Year in calculation) and the Interest is calculated as 11% of 1,00,000/- for 219 Days will be 6,600/and the Final Interest amount after 10% TDS Deduction is calculated as: 6,600-660(10% TDS)=5,940/- Will be credited/Reflected in the lender’s Bank Account.",
            "For the Second/Financial Year, number of days will be calculated till the second financial year end date i.e. from 01-04-2022 to 31-03-2023 and there are 365 days end date of Second Financial Year in calculation) and the Interest is calculated as 11% of 1,00,000/- for 365 Days will be 11,000/- and the Final Interest amount after 10% TDS Deduction is calculated as: 11,000-1,100(10% TDS)=9,900/- Will be credited/Reflected in the lender’s Bank Account.",
            "For the Third/Last Year, number of days will be calculated till the Maturity/End Date of plan i.e. till 30-09-2024 and there are 549 days (Including Maturity/End date Year in calculation) and the INTEREST is calculated as 11% of 1,00,000/- for 549 Days will be 16,545.20/- rounded to 16,546/- and the Final Interest amount after 10% TDSDeduction is calculated as: 16,546-1,654.6(10% TDS)=14,891.4/- Will be credited/Reflected Along with the Principal Amount in the lender’s Bank Account.",
          ],
        },
      ],
    },
  },

  {
    id: 15,
    question: "What is TDS and how to claim it?",
    data: {
      type: "para_with_link",
      answerwithlinklist: [
        {
          text: "Tax Deducted at Source (TDS) is the sum that is deducted from a taxpayer’s income like salary, interest from bank accounts, returns from any schemes/investment products, rent etc. If the TDS collected is more than what you owe to the government, you can get a TDS Refund.",
        },
        {
          text: "A TDS Refund arises when the taxes paid by way of TDS are greater than the actual tax payable calculated for the Financial Year. It is calculated after consolidating income earned from various sources.",
        },
        {
          text: "When the tax deducted does not match your actual tax payable, you can calculate your taxable income and taxes, file an income tax return (ITR) and claim a refund.",
        },
        {
          text: "To file your TDS online, you have to first register yourself on the IT department’s website-",
        },
        {
          linktype: "route",
          link: "https://incometaxindia.gov.in/Pages/Deposit_TDS_TCS.aspx ",
          linktext: "https://incometaxindiaefiling.gov.in",
        },
      ],
    },
  },
  {
    id: 16,
    question: "Can we PAUSE/STOP/CANCEL the plan once started?",
    data: {
      type: "para",
      answer: [
        "NO, Plan started/activated once will not be able to PAUSE/STOPPED/ or CANCELED in any circumstances, Plan will start once the amount is CREDITED/REFELECTED in S & S Investments Account.",
      ],
    },
  },
  {
    id: 17,
    question: "What is CUSTOMIZED PLAN and how it works?",
    data: {
      type: "para",
      answer: [
        "Unlike standard fixed interest rates, which are uniform for all the lenders, we at S & S Investments provides a customized interest rate plans which are designed to meet the unique needs and circumstances of individual lenders/investors or businesses.",
        "The customization of interest rates allows for a more personalized approach to our valued lenders/investors so that they can align with their financial capabilities and risk tolerance.",
       
      ],
      section: [
        { title:  "Benefits of customized interest rate plans include:" },
        {
          title: "Personalized Terms:",
          content: [
            "Terms that suits the financial situation, such as adjusting the Interest rates based on the Duration and horizon of the Lending capital.",
          ],
        },
        {
          title: "Flexible Repayment Options:",
          content: [
            "flexible repayment schedules and options, aligning with the lenders cash flow and financial goals.",
          ],
        },
        {
          title: "Risk-Based Pricing:",
          content: [
            "Interest rates are set in accordance with the perceived risk associated with the investor, allowing for fair and appropriate pricing based on the lenders financial standing.",
          ],
        },
        {
          title: "Relationship Building:",
          content: [
            "Customization fosters stronger relationships between S & S Investments and there valued Investors, promoting trust and loyalty.",
          ],
        },
      ],
      answerwithlink: [
        {
          text: "For exploring this plan feel free to fill up the form",
        },
        {
          linktype: "route",
          link: "contact-us/form",
          linktext: "click here",
        },
        {
          text: " and we will get in touch with you for the best deal.",
        },
      ],
    },
  },
  {
    id: 18,
    question:
      "What is “Financial Consultant”, how it is beneficial and how it works?",
    data: {
      type: "para_with_link",
      answerwithlink: [
        {
          text: "A unique service provided by executives and founders of S & S Investments, which will help you to plan your financial goals in terms of generating passive income, to manage your asset, different investment opportunities, trends in market, etc. and long life lesson on investments, This is an Exclusive Service available through advance appointment only,The mode of consulting is through personal video/audio calls Only, you can opt this exclusive service by sending us an Email on",
        },
        {
          linktype: "mail",
          link: "info@sandsinvestments.co.in ",
          linktext: "info@sandsinvestments.co.in",
        },
        { text: " ,mentioning FINANCIAL ADVICE in the subject of mail." },
      ],
    },
  },
  {
    id: 19,
    question: "Which are the different modes of transactions?",
    data: {
      type: "para",
      answer: [
        "Payments are Accepted and Credited only through CHEQUES/UPI/NETBANKING/IMPS/NEFT/RTGS/ONLINE PAYMENTS",
      ],
    },
  },
  {
    id: 20,
    question: "Cash Transactions are allowed?",
    data: {
      type: "para",
      answer: [
        "NO, we strictly not allow or deal with any kind of CASH Transactions.",
      ],
    },
  },
  {
    id: 21,
    question: "Have more questions/doubts, not listed in this section?",
    data: {
      type: "para_with_link",
      answerwithlink: [
        { text: "Kindly Mail us on" },
        {
          linktype: "mail",
          link: "info@sandsinvestments.co.in ",
          linktext: "info@sandsinvestments.co.in",
        },
        { text: " for more related queries by mentioning FAQ in subject" },
      ],
    },
  },
];
