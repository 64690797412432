import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import EnquireDiv from "../../../components/EnquireForm/EnquireDiv";
import RiskImage from "../../../assets/img/plan-detail/Risk.svg";
import InvestImage from "../../../assets/img/plan-detail/invest.svg";
import LockPeriodImage from "../../../assets/img/plan-detail/lock-period.svg";
import TaxImage from "../../../assets/img/plan-detail/tax.svg";
import "./ProductDetail.css";
import planHeroBg from "../../../assets/graphic/bg-graphic/plan-hero-banner-bg.svg";
import { useSelector } from "react-redux";

export const ProductData = [
  {
    planNumber: 1,
    plan: "silver",
    desc: [
      "A Simple basic plan which offers FIXED and GUARANTEED Interest Rate of 8% per Year/Annum.",
      "Lock in period duration is 1 Year/Annum.",
      "Minimum capital required is ONE Lakh (1,00,000/-) and after that Multiple of ONE Lakh.",
      "10% TDS will be Deducted on Interest Amount.",
      "Kindly go through FAQ Section for more details regarding What is the Process to lend capital in various plans ?, How maturity date is calculated?, How Interest rate is Calculated ?, Duration Calculation of particular plan, Required Details, and Many More.",
    ],
    return: 8,
    risk: "zero or no risk",
    TDS_Deduction: 10,
    period: 1,
    min_investment_in_lakhs: 1,
  },
  {
    planNumber: 2,
    plan: "gold",
    return: 9.5,
    desc: [
      "A Competitive plan which offers FIXED and GUARANTEED Interest Rate of 9.5% per Year/Annum.",
      "Lock in period duration is 2 Year/Annum.",
      "Minimum capital required is ONE Lakh (1,00,000/-) and after that Multiple of ONE Lakh.",
      "10% TDS will be Deducted on Interest Amount.",
      "Kindly go through FAQ Section for more details regarding What is the Process to lend capital in various plans ?, How maturity date is calculated?, How Interest rate is Calculated ?, Duration Calculation of particular plan, Required Details, and Many More.",
    ],
    risk: "zero or no risk",
    TDS_Deduction: 10,
    period: 2,
    min_investment_in_lakhs: 1,
  },
  {
    planNumber: 3,
    plan: "platinum",
    return: 11,
    desc: [
      "Our Most Valuable plan which offers FIXED and GUARANTEED Interest Rate of 11% per Year/Annum.",
      "Lock in period duration is 3 Year/Annum.",
      "Minimum capital required is ONE Lakh (1,00,000/-) and after that Multiple of ONE Lakh.",
      "10% TDS will be Deducted on Interest Amount.",
      "Kindly go through FAQ Section for more details regarding What is the Process to lend capital in various plans ?, How maturity date is calculated?, How Interest rate is Calculated ?, Duration Calculation of particular plan, Required Details, and Many More.",
    ],
    risk: "zero or no risk",
    TDS_Deduction: 10,
    period: 3,
    min_investment_in_lakhs: 1,
  },
];

const ProductDetail = () => {
  const planRouteId = useParams();
  const { subPlanTypes } = useSelector((state) => state.planData);

  const findPlan = subPlanTypes?.data?.find(
    (item) => item.plan_name === planRouteId.id2
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addNewlineAfterPeriod = (para) => {
    const paragraphs = para?.split('. ');
    return paragraphs?.map((sentence, index) => (
      <p key={index} className="mb-sm-2">
        {sentence}.
        {index !== paragraphs?.length - 1 && <br />}
      </p>
    ));
  };

  return (
    <div>
      {findPlan ? (
        <div className="overflow-hidden">
          <div className="main_container section-padding">
            <div className="graphic_hero_bg">
              <img src={planHeroBg} alt="planHeroBg" />
            </div>
            <div className="d-flex flex-column text-center">
              <h2 className="section-title">{findPlan?.plan_name}</h2>
              <p className="section-sub-title">{findPlan?.sub_title}</p>
            </div>

            <div className="d-flex flex-sm-row flex-column d-block w-75 w-sm-90 gap-4 mx-auto justify-content-between">
              <div className="col-sm-6">
                <h2 className="comman-title-light">
                  why invest in <br />
                  <span className="plan-title-text">
                    {findPlan?.plan_name} Plan?
                  </span>
                </h2>
                <p className="body-font text-center text-md-start">
                  {addNewlineAfterPeriod(findPlan?.desc)}
                </p>
              </div>
              <div className="col-sm-4 position-relative stem-tree">
                <div className="rate-div">
                  <span className="body-font">Return</span>
                  <span className="element-title">{findPlan?.rate}%</span>
                </div>
                <div className="tenure-div">
                  <span className="body-font">Tenure</span>
                  <span className="element-title">{findPlan?.tenure} year</span>
                </div>
              </div>
            </div>
            {/* marker-buzz tabs section */}
            <div class="tab-container section-padding-bottom">
              <div class="product-detail-tab-card">
                <div className="product-detail-tab-img">
                  <img src={LockPeriodImage} alt="img1" />
                </div>
                <div class="body-font fw-bold text-dark">
                  Lock in period is {findPlan?.tenure} year.
                </div>
              </div>
              <div class="product-detail-tab-card">
                <div className="product-detail-tab-img">
                  <img src={InvestImage} alt="img2" />
                </div>
                <div class="body-font fw-bold text-dark">
                  minimum investment of 1 (one) lakh
                </div>
              </div>
              <div class="product-detail-tab-card">
                <div className="product-detail-tab-img">
                  <img src={TaxImage} alt="img3" />
                </div>
                <div class="body-font fw-bold text-dark">
                  10% TDS Deducted on Interst Rate.
                </div>
              </div>
              <div class="product-detail-tab-card">
                <div className="product-detail-tab-img">
                  <img src={RiskImage} alt="img4" />
                </div>
                <div class="body-font fw-bold text-dark">zero or no risk</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Outlet />
      )}

      <div>
        <EnquireDiv />
      </div>
    </div>
  );
};

export default ProductDetail;
