import React from "react";
import './loading.css'

const Loading = () => {
  return (
    <div className="loading-Container">

    <div class="spinner-square">
      <div class="square-1 square"></div>
      <div class="square-2 square"></div>
      <div class="square-3 square"></div>
    </div>
    </div>
  );
};

export default Loading;
