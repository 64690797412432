import React, { useEffect, useState } from "react";
import cardIcon from "../../assets/img/icon/plancard/cash-wallet.png";
import graphicCoinIcon from "../../assets/graphic/coin.png";
import graphicLineIcon from "../../assets/graphic/lines.png";
import "./Product.css";
import { FaqSection } from "../../components/Faq/FaqSection";
import planHeroBg from "../../assets/graphic/bg-graphic/plan-hero-banner-bg.svg";
import { CustomSmallButton } from "../../components/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ProductData } from "./ProductDetail/ProductDetail";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  fetchPlanTypes,
  getPlanTypeAPi,
  getSubPlanTypeAPi,
} from "../../api/index";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";

const FixedPlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [clickedPlan, setShowClickPlan] = useState("");
  const { planTypes, subPlanTypes } = useSelector((state) => state.planData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = (plan_name) => {
    if (plan_name) {
      setShowClickPlan(plan_name);
      setShowModal(true);
    }
  };

  const findPlan = planTypes.data.find((item) => item.plan_name === id);

  useEffect(() => {
    getSubPlanTypeAPi(dispatch, findPlan?.id);
  }, [dispatch, findPlan?.id]);

  const handleAcceptTerm = () => {
    setShowModal(false);
    if (clickedPlan) {
      navigate(`/plans-services/${id}/${clickedPlan}`);
    }
  };

  return planTypes?.data?.length ? (
    <div>
      <div className="overflow-hidden">
        <div className="main_container section-padding">
          <div className="graphic_hero_bg">
            <img src={planHeroBg} alt="planHeroBg" />
          </div>

          <div className="d-flex flex-column text-center">
            <h2 className="section-title">{findPlan?.plan_name}</h2>
            <p className="section-sub-title">{findPlan?.sub_title}</p>
          </div>
          <div className="d-flex flex-sm-row flex-column d-block w-75 w-sm-90 gap-4 mx-auto justify-content-between">
            <div className="col-sm-6">
              <h2 className="comman-title-light">
                What is <br />
                <span className="plan-title-text">{findPlan?.plan_name}?</span>
              </h2>
              <p className="body-font text-center text-md-start  ">
                {findPlan?.desc}
              </p>
            </div>
            <div className="col-sm-4 position-relative  stem-tree">
              <div className="rate-div">
                <span className="body-font">Return</span>
                <span className="element-title">
                  {findPlan.roi_min}%-{findPlan.roi_max}%
                </span>
              </div>
              <div className="tenure-div">
                <span className="body-font">Tenure</span>
                <span className="element-title">
                  {findPlan.tenure_min}-{findPlan.tenure_max} years
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="plan_card_container">
        <div className="graphic_coin">
          <img src={graphicCoinIcon} alt="graphicCoinIcon" />
        </div>
        <div className="graphic_lines">
          <img src={graphicLineIcon} alt="graphicCoinIcon" />
        </div>
        {/* plan 1 */}
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
          breakpoints={{
            // when window width is >= 768px (for example, on tablets and desktop)
            768: {
              slidesPerView: 3,
            },
            // when window width is < 768px (for example, on mobile)
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {subPlanTypes?.data?.map((item, index) => (
            <SwiperSlide>
              <div className="plan_card">
                <div className="plan_img_div">
                  <img alt="plan_img_1" src={cardIcon} />
                </div>
                <div className="plan_text_container">
                  <div className="plan_no_1">{index + 1}</div>
                  <div className="sub_plan_title">{item?.plan_name}</div>
                  <div className="small-body-font">
                    {item?.desc?.length > 80
                      ? `${item?.desc?.slice(0, 82)}...`
                      : item?.desc}
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    onClick={() => openModal(item?.plan_name)}
                  >
                    <CustomSmallButton title="view plan" color="#0098DA" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="section-padding bg-white">
        <FaqSection />
      </div>

      {/* model  */}
      <div
        className={showModal ? "modal fade show" : "modal fade "}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title element-title-2"
                id="staticBackdropLabel"
              >
                terms of service / i understand
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body small-body-font">
              <p className="mb-20">
                With this the Lender/Investor/User/Client AGREES and UNDERSTANDS
                that he or she is investing the capital as a LENDER in S &amp; S
                Investments and will get FIXED INTEREST AMOUNT ANUALLY (AS
                Mentioned in the FAQ Section) (Depending on the Plan’s that the
                Lender has selected i.e. FIXED or CUSTOMIZED INTEREST RATES),
                and S &amp; S Investments is BORROWING the Capital as a LOAN
                AMOUNT at the interest rates specified in the Plan (FIXED or
                CUSTOMIZED INTEREST RATES) Selected or Chosen by the Lender and
                provided by S &amp; S Investments.
              </p>

              <ul>
                <li className="mb-20">
                  I understand that the LENDER bear INTEREST RATE as per the
                  SELECTED PLAN respectively per annum on the amount of initial
                  capital. [Depends on the Plan Selected, Kindly go to FAQ
                  Section thoroughly to understand the calculation of interest
                  amounts and how it will be credited in Lenders bank account].
                </li>
                <li className="mb-20">
                  I hereby declare that the information such as (Name, Contact
                  Information, Bank Details, Payment Details, ID Proof’s, etc.)
                  provided by me through Emails, Website or through any other
                  types of communication media’s, and which is available and
                  collected by S &amp; S Investments for safe and secure
                  financial transactions is true to the best of my knowledge and
                  belief and nothing has been concealed therein. I understand
                  that if the information given by me is proved false/not true,
                  I will have to face the punishment as per the law.
                </li>
                <li className="mb-20">
                  I allow and understands that the Information collected by S
                  &amp; S Investments is for only SAFE and SECURE financial
                  transactions.
                </li>

                <li className="mb-20">
                  I understand that the Plan START Date is the date on which the
                  selected plan’s Amount is credited/Reflected in S &amp; S
                  Investments Account.
                </li>
                <li className="mb-20">
                  I understand that the Plan END Date also known as Maturity
                  Date of Plan is the date on which the Principal Amount and the
                  FINAL Interest will be credited/Reflected in Lender’s/Clients
                  Account. [Depends on the Plan Selected].
                </li>

                <li className="mb-20">
                  I understand that the Standard TDS Deduction will be applied
                  on INTEREST AMOUNT.
                </li>

                <li className="mb-20">
                  I understand and does not have any doubts regarding the
                  calculation of Plan duration.
                </li>

                <li className="mb-20">
                  I understand and does not have any doubts regarding the
                  calculation of interest amount and how it will be credited in
                  the lender’s account.
                </li>

                <li className="mb-20">
                  I understood the PLAN clearly (with all the criteria for
                  interested plan) prior to lending the capital.
                </li>

                <li className="mb-20">
                  I understand that the Plan started/activated once will not be
                  able to PAUSE/STOPPED/ or CANCELED in any circumstances, Plan
                  will start once the amount is CREDITED/REFELECTED in S &amp; S
                  Investments Account.
                </li>

                <li className="mb-20">
                  I have read the FAQ Section thoroughly and understood and does
                  not have any doubts regarding the details of Plans for
                  investments.
                </li>
                <li className="mb-20">
                  S&amp;S Investments reserves and holds all the rights for the
                  CHANGES and UPDATES in the Policies, Plans and Conditions or
                  any other Criteria’s for various investment Plans.
                </li>
              </ul>
            </div>
            <div
              className="modal-footer"
              onClick={() => handleAcceptTerm()}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <CustomSmallButton title="i understand" color="#0098DA" />
            </div>
          </div>
        </div>
      </div>

      {/* model  */}
    </div>
  ) : (
    <div>
      <div className="overflow-hidden">
        <div className="main_container section-padding">
          <div className="graphic_hero_bg">
            <img src={planHeroBg} alt="planHeroBg" />
          </div>

          <div className="d-flex flex-column text-center">
            <h2 className="section-title">fixed interest rates plan</h2>
            <p className="section-sub-title">
              Raising capital in the most efficient way
            </p>
          </div>
          <div className="d-flex flex-sm-row flex-column d-block w-75 w-sm-90 gap-4 mx-auto justify-content-between">
            <div className="col-sm-6">
              <h2 className="comman-title-light">
                What is <br />
                <span className="plan-title-text">
                  Fixed Interest Rates Plan?
                </span>
              </h2>
              <p className="body-font text-center text-md-start  ">
                Our fixed interest rate plans offer you a steadfast path to
                financial growth and security. Fixed Interest Rate plans are the
                borrowing capital instruments that offer a predictable and
                stable interests on your capital lended/invested over a
                predetermined period. These plans typically provide a fixed
                interest rate or guaranteed returns, making them a low-risk
                option for lenders/investors seeking a steady income stream this
                plan is favoured by individuals and institutions looking for a
                safe and reliable way to grow their savings without exposure to
                the volatility of the financial market.
              </p>
            </div>
            <div className="col-sm-4 position-relative  stem-tree">
              <div className="rate-div">
                <span className="body-font">Return</span>
                <span className="element-title">8%-11%</span>
              </div>
              <div className="tenure-div">
                <span className="body-font">Tenure</span>
                <span className="element-title">1-3 years</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="plan_card_container">
        <div className="graphic_coin">
          <img src={graphicCoinIcon} alt="graphicCoinIcon" />
        </div>
        <div className="graphic_lines">
          <img src={graphicLineIcon} alt="graphicCoinIcon" />
        </div>
        {/* plan 1 */}
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
          breakpoints={{
            // when window width is >= 768px (for example, on tablets and desktop)
            768: {
              slidesPerView: 3,
            },
            // when window width is < 768px (for example, on mobile)
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {" "}
          {ProductData.map((item) => (
            <SwiperSlide>
              <div className="plan_card">
                <div className="plan_img_div">
                  <img alt="plan_img_1" src={cardIcon} />
                </div>
                <div className="plan_text_container">
                  <div className="plan_no_1">{item?.planNumber}</div>
                  <div className="sub_plan_title">{item?.plan}</div>
                  <div className="small-body-font">{item?.desc[0]}</div>
                  <div
                    className="d-flex justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    onClick={() => openModal(item?.plan)}
                  >
                    <CustomSmallButton title="view plan" color="#0098DA" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="section-padding bg-white">
        <FaqSection />
      </div>

      {/* model  */}
      <div
        className={showModal ? "modal fade show" : "modal fade "}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title element-title-2"
                id="staticBackdropLabel"
              >
                terms of service / i understand
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body small-body-font">
              <p className="mb-20">
                With this the Lender/Investor/User/Client AGREES and UNDERSTANDS
                that he or she is investing the capital as a LENDER in S &amp; S
                Investments and will get FIXED INTEREST AMOUNT ANUALLY (AS
                Mentioned in the FAQ Section) (Depending on the Plan’s that the
                Lender has selected i.e. FIXED or CUSTOMIZED INTEREST RATES),
                and S &amp; S Investments is BORROWING the Capital as a LOAN
                AMOUNT at the interest rates specified in the Plan (FIXED or
                CUSTOMIZED INTEREST RATES) Selected or Chosen by the Lender and
                provided by S &amp; S Investments.
              </p>

              <ul>
                <li className="mb-20">
                  I understand that the LENDER bear INTEREST RATE as per the
                  SELECTED PLAN respectively per annum on the amount of initial
                  capital. [Depends on the Plan Selected, Kindly go to FAQ
                  Section thoroughly to understand the calculation of interest
                  amounts and how it will be credited in Lenders bank account].
                </li>
                <li className="mb-20">
                  I hereby declare that the information such as (Name, Contact
                  Information, Bank Details, Payment Details, ID Proof’s, etc.)
                  provided by me through Emails, Website or through any other
                  types of communication media’s, and which is available and
                  collected by S &amp; S Investments for safe and secure
                  financial transactions is true to the best of my knowledge and
                  belief and nothing has been concealed therein. I understand
                  that if the information given by me is proved false/not true,
                  I will have to face the punishment as per the law.
                </li>
                <li className="mb-20">
                  I allow and understands that the Information collected by S
                  &amp; S Investments is for only SAFE and SECURE financial
                  transactions.
                </li>

                <li className="mb-20">
                  I understand that the Plan START Date is the date on which the
                  selected plan’s Amount is credited/Reflected in S &amp; S
                  Investments Account.
                </li>
                <li className="mb-20">
                  I understand that the Plan END Date also known as Maturity
                  Date of Plan is the date on which the Principal Amount and the
                  FINAL Interest will be credited/Reflected in Lender’s/Clients
                  Account. [Depends on the Plan Selected].
                </li>

                <li className="mb-20">
                  I understand that the Standard TDS Deduction will be applied
                  on INTEREST AMOUNT.
                </li>

                <li className="mb-20">
                  I understand and does not have any doubts regarding the
                  calculation of Plan duration.
                </li>

                <li className="mb-20">
                  I understand and does not have any doubts regarding the
                  calculation of interest amount and how it will be credited in
                  the lender’s account.
                </li>

                <li className="mb-20">
                  I understood the PLAN clearly (with all the criteria for
                  interested plan) prior to lending the capital.
                </li>

                <li className="mb-20">
                  I understand that the Plan started/activated once will not be
                  able to PAUSE/STOPPED/ or CANCELED in any circumstances, Plan
                  will start once the amount is CREDITED/REFELECTED in S &amp; S
                  Investments Account.
                </li>

                <li className="mb-20">
                  I have read the FAQ Section thoroughly and understood and does
                  not have any doubts regarding the details of Plans for
                  investments.
                </li>
                <li className="mb-20">
                  S&amp;S Investments reserves and holds all the rights for the
                  CHANGES and UPDATES in the Policies, Plans and Conditions or
                  any other Criteria’s for various investment Plans.
                </li>
              </ul>
            </div>
            <div
              className="modal-footer"
              onClick={() => handleAcceptTerm()}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <CustomSmallButton title="i understand" color="#0098DA" />
            </div>
          </div>
        </div>
      </div>

      {/* model  */}
    </div>
  );
};

export default FixedPlan;
