import React, { useEffect } from "react";
import graphicCoinIcon from "../../assets/graphic/coin.png";
import graphicLineIcon from "../../assets/graphic/lines.png";
import "./Product.css";
import EnquireDiv from "../../components/EnquireForm/EnquireDiv";
import { FaqSection } from "../../components/Faq/FaqSection";
import planHeroBg from "../../assets/graphic/bg-graphic/plan-hero-banner-bg-2.svg";
import CustomPlanImg from "../../assets/img/custom-plan-img.svg";
import { NavLink } from "react-router-dom";

const CustomPlan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="overflow-hidden">
        <div className="main_container section-padding">
          <div className="custom_hero_bg">
            <img src={planHeroBg} alt="planHeroBg" />
          </div>

          <div className="d-flex flex-column text-center">
            <h2 className="section-title">Customized plan</h2>
            <p className="section-sub-title">
              Raising capital in the most efficient way
            </p>
          </div>

          <div className="d-flex flex-sm-row flex-column d-block w-75 w-sm-90 gap-sm-5 mx-auto align-items-center justify-content-between">
            <div className="col-sm-6">
              <h2 className="comman-title-light">
                What is <br />
                <span className="plan-title-text">Customized Plan?</span>
              </h2>
              <p className="body-font text-center text-md-start ">
                Unlike standard fixed interest rates, which are uniform for all
                the lenders, we at S & S Investments provides a customized
                interest rate plans which are designed to meet the unique needs
                and circumstances of individual lenders/investors or businesses.
                The customization of interest rates allows for a more
                personalized approach to our valued lenders/investors so that
                they can align with their financial capabilities and risk
                tolerance.
              </p>
            </div>
            <div className="img-section">
              <img
                src={CustomPlanImg}
                className="img-fluid img-size"
                alt="custom-img"
              />
            </div>
          </div>
        </div>

        <div className="plan_card_container">
          <div className="graphic_coin">
            <img src={graphicCoinIcon} alt="graphicCoinIcon" />
          </div>
          <div className="graphic_lines">
            <img src={graphicLineIcon} alt="graphicCoinIcon" />
          </div>
          {/* plan 1 */}
          <div className="comman-x-card">
            <div className="plan_text_container text-start ">
              <div className="sub_plan_title mb-20">
                Your Path to Financial Success
              </div>
              <div className="body-font mb-20">
                <p className="fw-bold">
                  Benefits of customized interest rate plans include:
                </p>
                <ul className="pl-2">
                  <li className="mb-20">
                    <b> Personalized Terms:</b> Terms that suits the financial
                    situation, such as adjusting the Interest rates based on the
                    Duration and horizon of the Lending capital.
                  </li>
                  <li className="mb-20">
                    <b> Flexible Repayment Options:</b> flexible repayment
                    schedules and options, aligning with the lenders cash flow
                    and financial goals.
                  </li>
                  <li className="mb-20">
                    <b> Risk-Based Pricing:</b> Interest rates are set in
                    accordance with the perceived risk associated with the
                    investor, allowing for fair and appropriate pricing based on
                    the lenders financial standing.
                  </li>
                  <li className="mb-20">
                    <b> Relationship Building:</b> Customization fosters
                    stronger relationships between S & S Investments and there
                    valued Lenders, promoting trust and loyalty.
                  </li>
                </ul>
                <span className="body-font">
                  For exploring this plan feel free to fill up the form{" "}
                  <NavLink
                    to="/contact-us/form"
                    className="text-decoration-none text-lowercase"
                  >
                    click here
                  </NavLink>{" "}
                  and we will get in touch with you for the best deal.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <EnquireDiv />
      </div>
      <div className=" section-padding bg-white">
        <FaqSection />
      </div>
    </div>
  );
};

export default CustomPlan;
