import React, { useEffect } from "react";
import "./Product.css";
import EnquireDiv from "../../components/EnquireForm/EnquireDiv";
import { FaqSection } from "../../components/Faq/FaqSection";
import planHeroBg from "../../assets/graphic/bg-graphic/plan-hero-banner-bg-2.svg";
import ConsultantPageImg from "../../assets/img/consultant-page-img.svg";

const ConsultantPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="overflow-hidden">
        <div className="main_container section-padding">
          <div className="custom_hero_bg">
            <img src={planHeroBg} alt="planHeroBg" />
          </div>

          <div className="d-flex flex-column text-center">
            <h2 className="section-title">Financial consultant</h2>
            <p className="section-sub-title">
              Raising capital in the most efficient way
            </p>
          </div>

          <div className="d-flex flex-sm-row flex-column d-block w-75 w-sm-90 gap-sm-5 mx-auto align-items-center justify-content-between">
            <div className="col-sm-6">
              <h2 className="comman-title-light">
                What is <br />
                <span className="plan-title-text">Financial consultant?</span>
              </h2>
              <p className="body-font text-center text-md-start ">
                A unique service provided by executives and founders of S &amp;
                S Investments, which will help you to plan your financial goals
                in terms of generating passive income, to manage your asset,
                different investment opportunities, trends in market, etc. and
                long life lesson on investments, This is an Exclusive Service
                available through advance appointment only, The mode of
                consulting is through personal video/audio calls Only, you can
                opt this exclusive service by sending us an Email on {""}
                <a
                  href="mailto: info@sandsinvestments.co.in"
                  target="_blank"
                  rel="noreferrer"
                  className="text-lowercase text-decoration-none "
                >
                  info@sandsinvestments.co.in
                </a>
                ,{" "}mentioning FINANCIAL ADVICE in the subject of mail.
              </p>
            </div>
            <div className="img-section">
              <img
                src={ConsultantPageImg}
                className="img-fluid img-size"
                alt="custom-img"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <EnquireDiv />
      </div>
      <div className=" section-padding bg-white">
        <FaqSection />
      </div>
    </div>
  );
};

export default ConsultantPage;
