import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from "../../../assets/logo/S_S Investment.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "../../../redux-store/Login/Login.action";
import { toast } from "react-toastify";
import { apiClient } from "../../../api";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
 

  // useEffect(() => {
  //   if (isLogin) {
  //     navigate("/");
  //   }
  // }, [isLogin, navigate]);
  
  const initialValues = {
    receipt_no: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    receipt_no: Yup.string().required("Receipt No is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = async (values) => {
    try {
      const response = await apiClient.post(`/customer-login`, {
        receipt_no: values.receipt_no,
        password: values.password,
      });

      if (response.status === 200) {
        toast.success("Successfully Logged In", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(getLogin(response.data));
        navigate("/my-account");
      }
      
    } catch (error) {
      toast.error("Receipt No and Password are wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    toast.warning(
      "Forgotten your password? No worries! Simply shoot us an email at info@sandsinvestments.co.in",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card" style={{ width: "28rem" }}>
        <div className="card-body p-5">
          <div className="text-center mb-4">
            <img src={Logo} alt="Logo" width="200" className="img-fluid" />
          </div>
          <p className="body-font text-center mb-4">Welcome Back.</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <div className="mb-3">
                <label htmlFor="receipt_no" className="form-label body-font">
                  Receipt no.
                </label>
                <Field
                  type="text"
                  className="auth-form-control"
                  id="receipt_no"
                  name="receipt_no"
                />
                <ErrorMessage
                  name="receipt_no"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label body-font">
                  Password
                </label>
                <div className="input-group">
                  <Field
                    type={showPassword ? "text" : "password"}
                    className="auth-form-control"
                    id="password"
                    name="password"
                  />
                  <button
                    className="showPasswordBtn"
                    type="button"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </button>
                </div>

                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
                <div
                  style={{ cursor: "divointer" }}
                  className="d-flex  justify-content-center  m-2 "
                  onClick={handleForgotPassword}
                >
                  <span className="body-font" style={{ cursor: "pointer" }}>
                    Forgot password?
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-sm-4">
                <CustomButton title="Login" color="#0098DA" type="submit">
                  Login
                </CustomButton>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
