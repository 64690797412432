import React, { useEffect } from "react";
import planHeroBg from "../../assets/graphic/bg-graphic/plan-hero-banner-bg.svg";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="section-padding">
        <div className="graphic_hero_bg">
          <img src={planHeroBg} alt="planHeroBg" />
        </div>

        <div className="d-flex flex-column text-center">
          <h2 className="section-title">Privacy Policy </h2>
        </div>
        <div className="blog-detail-image mb-26">
          {/* {/ <img src={BlogImg} alt="blog-img" /> /} */}
        </div>

        <div className="term-page-container ">
          <h2 className="element-title-1 mb-20">1.Introduction</h2>

          <p className="body-font mb-26">
            S &amp; S Investments (&quot;we,&quot; &quot;our,&quot; or
            &quot;us&quot;) is committed to protecting the privacy of our
            clients and visitors. This privacy policy outlines our practices
            concerning the collection, use, and disclosure of personal
            information. By using our services, you consent to the terms of this
            policy.
          </p>
          {/* point 2 */}
          <h2 className="element-title-1 mb-20">2.Information We Collect</h2>

          <p className="body-font mb-26">
            We may collect personal information, including but not limited to:
          </p>
          <ul>
            <li className="body-font mb-26">
              Contact Information: Name, address, email address, and phone
              number.
            </li>
            <li className="body-font mb-26">
              Financial Information: Bank account details, investment
              preferences, and transaction history.
            </li>
            <li className="body-font mb-26">
              Identification Information: PAN number, government-issued
              identification details.
            </li>
            <li className="body-font mb-26">
              Log Data: Information about your use of our website, including IP
              address, browser type, and pages visited.
            </li>
          </ul>
          {/* point 3 */}
          <h2 className="element-title-1 mb-20">
            3.How We Use Your Information
          </h2>

          <p className="body-font mb-26">
            We use the collected information for the following purposes:
          </p>
          <ul>
            <li className="body-font mb-26">
              To provide and maintain our services.
            </li>
            <li className="body-font mb-26">
              To process transactions and manage your investment accounts.
            </li>
            <li className="body-font mb-26">
              To send periodic updates, newsletters, and other communications.
            </li>
            <li className="body-font mb-26">
              To comply with legal and regulatory requirements.
            </li>
          </ul>

          {/* 4th point */}
          <h2 className="element-title-1 mb-20">4.Information Sharing</h2>

          <p className="body-font mb-26">
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            described in this policy. We may share your information with:
          </p>
          <p className="body-font mb-26">
            Service Providers: Third-party service providers who assist us in
            operating our website or conducting our business.
          </p>
          <p className="body-font mb-26">
            Legal Compliance: To comply with applicable laws, regulations, or
            legal processes.
          </p>
          {/* point 5 */}
          <h2 className="element-title-1 mb-20">5.Security</h2>

          <p className="body-font mb-26">
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          {/* point 6 */}
          <h2 className="element-title-1 mb-20">
            6.Cookies and Tracking Technologies
          </h2>

          <p className="body-font mb-26">
            We use cookies and similar technologies to enhance your experience
            on our website. You can modify your browser settings to disable
            cookies, but this may affect your ability to access certain features
            of our site.
          </p>
          {/* point 7 */}
          <h2 className="element-title-1 mb-20">7.Your Choices</h2>

          <p className="body-font mb-26">
            You can opt-out of receiving marketing communications from us by
            following the unsubscribe instructions provided in the
            communication.
          </p>
          {/* point 8 */}
          <h2 className="element-title-1 mb-20">8.Links to Other Sites</h2>

          <p className="body-font mb-26">
            Our Site may contain links to other sites that are not operated by
            us. If you click on a third-party link, you will be directed to that
            third party&#39;s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          {/* point 9 */}
          <h2 className="element-title-1 mb-20">
            9.Changes to This Privacy Policy
          </h2>

          <p className="body-font mb-26">
            This Privacy Policy is effective as of 21-11-2023 and will remain in
            effect except with respect to any changes in its provisions in the
            future, which will be in effect immediately after being posted on
            this page.
          </p>

          <p className="body-font mb-26">
            We reserve the right to update or change our Privacy Policy at any
            time, and you should check this Privacy Policy periodically. Your
            continued use of the Service after we post any modifications to the
            Privacy Policy on this page will constitute your acknowledgment of
            the modifications and your consent to abide and be bound by the
            modified Privacy Policy.
          </p>
          {/* point 10 */}
          <h2 className="element-title-1 mb-20">10.Contact Us</h2>
          <p className="body-font mb-26">
            If you have any questions or concerns about our privacy policy and
            practices, please drop us an email on {""}
            <a
              className="text-decoration-none text-lowercase "
              href="mailto:info@sandsinvestments.co.in"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              info@sandsinvestments.co.in
            </a>{" "}
            .
          </p>
        </div>
      </div>
    </div>
  );
};
