import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Home from "../pages/home/Home";
import CustomPlan from "../pages/product/CustomPlan";
import FixedPlan from "../pages/product/FixedPlan";
import MarketBuzz from "../pages/dicover/markte-buzz/MarketBuzz";
import ProductDetail from "../pages/product/ProductDetail/ProductDetail";
import FaqPage from "../components/Faq/FaqPage";
import MyAccount from "../pages/my-account/MyAccount";
import { PrivacyPolicy} from "../pages/privacy-policy/PrivacyPolicy";
import ConsultantPage from "../pages/product/ConsultantPage";
import ChangePassword from "../pages/auth/change-password/ChangePassword";
import LoginPage from "../pages/auth/Login/Login";
import DownloadPdf from "../components/DownLoadPdf/DownloadPdf";
import { TermsServices } from "../pages/terms-services/TermsServices";

export const routes = [
  {
    route: "login",
    isPublic: true,
    component: <LoginPage />,
  },
  {
    route: "download",
    isPublic: true,
    component: <DownloadPdf />,
  },
  {
    route: "change-password",
    isPrivate: true,
    component: <ChangePassword />,
  },
  {
    route: "/",
    isPublic: true,
    component: <Home />,
  },
  {
    route: "/plans-services/:id",
    isPublic: true,
    component: <FixedPlan />,
  },
  {
    route: "/plans-services/:id1/:id2",
    isPublic: true,
    component: <ProductDetail />,
  },
  {
    route: "/customized-plan",
    isPublic: true,
    component: <CustomPlan />,
  },
  {
    route: "/financial-consultant",
    isPublic: true,
    component: <ConsultantPage />,
  },
  {
    route: "/about-us",
    isPublic: true,
    component: <About />,
  },
  {
    route: "/contact-us",
    isPublic: true,
    component: <Contact />,
  },
  {
    route: "/contact-us/:id",
    isPublic: true,
    component: <Contact />,
  },
  {
    route: "/market-buzz",
    isPublic: true,
    component: <MarketBuzz />,
  },
  {
    route: "/faq",
    isPublic: true,
    component: <FaqPage />,
  },
  {
    route: "/my-account",
    isPrivate: true,
    component: <MyAccount />,
  },
  {
    route: "/privacy-policy",
    isPublic: true,
    component: <PrivacyPolicy />,
  },
  {
    route: "/terms-services",
    isPublic: true,
    component: <TermsServices />,
  },
];
