
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Logo from "../../../assets/logo/S_S Investment.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getLogout } from "../../../redux-store/Login/Login.action";
import { toast } from "react-toastify";
import { apiClient } from "../../../api";
import * as Yup from "yup";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.loginData);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Old Password is required"),
    newpassword: Yup.string()
      .required("New Password is required")
      .matches(/^.{8,}$/, {
        message: "Password must contain at least 8 characters, including",
        excludeEmptyString: true,
      })
      .test("has-uppercase", "1 uppercase letter is required", (value) =>
        /[A-Z]/.test(value)
      )
      .test("has-lowercase", "1 lowercase letter is required", (value) =>
        /[a-z]/.test(value)
      )
      .test("has-digit", "1 digit is required", (value) => /\d/.test(value))
      .test("not-same-as-receipt-no", "New password cannot be the same as the receipt number", (value, { parent }) => {
        return value !== parent.receipt_no;
      })
      .test(
        "has-special-character",
        "1 special character is required",
        (value) => /[@$!_%*?&]/.test(value)
      ),
    confirmpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newpassword")], "Passwords do not match"),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      
      const response = await apiClient.put(
        `/change-password`,
        {
          userid: data?.data?.id,
          receipt_no: data?.data?.receipt_no,
          oldpassword: values.oldpassword,
          newpassword: values.newpassword,
        },
        {
          headers: {
            Authorization: `Bearer ${data?.token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Password changed successfully! Please login again", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(getLogout());
        navigate("/login");
      }
    } catch (error) {
      toast.error("Old password is wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/login", { replace: true });
      dispatch(getLogout());
    } finally {
      setSubmitting(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card" style={{ width: "28rem" }}>
        <div className="card-body p-5">
          <div className="text-center mb-4">
            <img src={Logo} alt="Logo" width="200" className="img-fluid" />
          </div>
          <p className="body-font text-center mb-4">
            Please set a new password
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {/* Old Password */}
                <div className="mb-3">
                  <label htmlFor="oldpassword" className="form-label body-font">
                    Old Password
                  </label>
                  <Field
                    type="password"
                    name="oldpassword"
                    className="auth-form-control"
                  />
                  <ErrorMessage
                    name="oldpassword"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* New Password */}
                <div className="mb-3">
                  <label htmlFor="newpassword" className="form-label body-font">
                    New Password
                  </label>
                  <div className="input-group">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="newpassword"
                      className="auth-form-control"
                    />
                    <button
                      className="showPasswordBtn"
                      type="button"
                      onClick={toggleShowPassword}
                      // style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name="newpassword"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Confirm Password */}
                <div className="mb-3">
                  <label
                    htmlFor="confirmpassword"
                    className="form-label body-font"
                  >
                    Confirm Password
                  </label>
                  <Field
                    type={values.showPassword ? "text" : "password"}
                    name="confirmpassword"
                    className="auth-form-control"
                  />
                  <ErrorMessage
                    name="confirmpassword"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="d-flex justify-content-center m-auto">
                  <CustomButton
                    title="Change password"
                    color="#0098DA"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
