import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import Footer from "./components/Footer/Footer";
import { useSelector } from "react-redux";
import Navbar from "./components/Navbar/Navbar";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute";
import PublicRoute from "./routes/PublicRoute/PublicRoute";

function App() {
  
  const Layout = ({ children }) => {
    return (
      <div>
        <Navbar />
        <div className="min-vh-100">{children}</div>
        <Footer />
      </div>
    );
  };

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      let CustomRoute = route.isPrivate ? PrivateRoute : PublicRoute;
      if (route.isPublic) {
        return (
          <Route
            path={route.route}
            element={route.component}
            key={route.route}
          />
        );
      }

      if (route.isPrivate) {
        return (
          <Route
            path={route.route}
            element={<CustomRoute>{route.component}</CustomRoute>}
            key={route.route}
          />
        );
      }

      return null;
    });
  };

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
