import React, { useEffect, useRef } from "react";
import "./Contact.css";
import contactBannerImg from "../../assets/img/contact-us-header.svg";
import Send from "../../assets/img/icon/Send.svg";
import Msg from "../../assets/img/icon/msg.svg";
import Fb from "../../assets/img/icon/fb.svg";
import Insta from "../../assets/img/icon/insta.svg";
import Location from "../../assets/img/icon/location.svg";
import {
  CustomSubmitButton,
} from "../../components/CustomButton/CustomButton";
import contactForm from "../../assets/img/contact-form.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiClient } from "../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Contact = () => {
  const contactFormRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    if (id === "form" && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [id]);
  
  const initialValues = {
    name: "",
    surname: "",
    email: "",
    contact_no: "",
    enquiry_for: "",
    city: "",
    state: "",
    conversation_type: "call",
    accept_terms: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only letters and spaces are allowed")
      .required("First Name is required"),
    surname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only letters and spaces are allowed")
      .required("Last Name is required"),
    enquiry_for: Yup.string().required("Message is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contact_no: Yup.string()
      .max(10, "Number must be 10 digits")
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .required("Phone number is required"),

    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    accept_terms: Yup.boolean().oneOf(
      [true],
      "Please agree to the terms before submitting the form"
    ),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (values) {
      apiClient.post("/add-contact-us", values);
      toast.success("We Successfully Received Your Query", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    resetForm();
  };

  return (
    <div className="page-wrapper">
      <div className="contact-banner">
        <div className="contact-main-banner-div">
          <div className="contact-inner-banner">
            <div className="contact-banner-left">
              <div className="element-title">
                <strong>We would love to hear from you</strong>
              </div>
              <div className="small-body-font">
                S &amp; S Investments is a reliable investment company that
                offers a range of fixed, customized interest rate plans and
                opportunities to their clients. S &amp; S Investments welcomes
                inquiries and communication from potential Lender’s/Investors,
                partners, and anyone interested in our valued services. You can
                reach out to us via email, Website, or by visiting our social
                media platforms for more information about our plans,
                opportunities and services.
              </div>
            </div>
            <div className="about-banner-right">
              <div className="banner-img-sec">
                <img src={contactBannerImg} alt="contact-banner-img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-section">
        <div className="contact-info">
          <div className="contact-card">
            <div className="contact-item">
              {/* <img src={Call} className="contact-icon" alt="Call Us Icon" /> */}
              <div className="contact-text">
                <div className="body-font fw-bold text-dark">Availability</div>
                <span className="contact-link">
                  Monday to Friday 10:00 AM to 5:00 PM
                </span>
              </div>
            </div>
          </div>
          <div className="contact-card">
            <div className="contact-item">
              <img src={Msg} className="contact-icon" alt="Email Us Icon" />
              <div className="contact-text">
                <div className="body-font fw-bold text-dark">Email Us</div>
                <a
                  className="contact-link"
                  href="mailto:info@sandsinvestments.co.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@sandsinvestments.co.in
                </a>
              </div>
            </div>
          </div>
          <div className="contact-card">
            <div className="contact-item">
              <img
                src={Send}
                className="contact-icon"
                alt="Connect With Us Icon"
              />
              <div className="contact-text">
                <div className="body-font fw-bold text-dark">
                  Connect With Us
                </div>
                <div className="social-links">
                  <a
                    href="https://www.instagram.com/sands_investments/?igshid=MzRlODBiNWFlZA%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Insta} className="social-icon" alt="Instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/people/S-S-Investments/100085082064092/?mibextid=Zb%20%20%20%20%20%20%20%20%20%20%20%20WKwL"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Fb} className="social-icon" alt="Facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-address" >
          <div className="address-section">
            <div className="body-font fw-bold text-dark">We are Located at</div>
            <div className="address-details">
              <img
                src={Location}
                className="address-icon"
                alt="Location Icon"
              />
              <div className="body-font">
                D-505, Shree Narayan Exotica, Gurukul Road, Memnagar, Ahmedabad,
                Gujarat-380052, India.
              </div>
            </div>
            <div className="address-details">
              <img
                src={Location}
                className="address-icon"
                alt="Location Icon"
              />
              <div className="body-font">
                Shop No. 41 Gulshan Plaza, Near Mantri Motors, Badnera Road,
                Rajapeth, Amravati, Maharashtra-444601, India.
              </div>
            </div>
            <div>
              <img src={contactForm} className="img-fluid" alt="contact-img" />
            </div>
          </div>

          <div className="write-to-us">
            <div className="body-font fw-bold text-dark"  ref={contactFormRef}>Write to us</div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="body-font" >
                <div className="d-flex gap-3 ">
                  <div className="form-field">
                    <label htmlFor="firstname">First Name</label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="form-input"
                      placeholder="Enter your First Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-field">
                    <label htmlFor="surname">Last Name</label>
                    <Field
                      type="text"
                      id="surname"
                      name="surname"
                      className="form-input"
                      placeholder="Enter your Last Name"
                    />
                    <ErrorMessage
                      name="surname"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="form-field">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-input"
                    placeholder="Enter your Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="contact_no">Phone Number</label>
                  <Field
                    type="number"
                    // type="tel"
                    id="contact_no"
                    name="contact_no"
                    className="form-input"
                    placeholder="Enter Phone Number"
                  />
                  <ErrorMessage
                    name="contact_no"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="enquiry_for">enquiry for </label>
                  <Field
                    type="text"
                    // type="tel"
                    id="enquiry_for"
                    name="enquiry_for"
                    className="form-input text-area"
                    placeholder="Enter message"
                  />
                  <ErrorMessage
                    name="enquiry_for"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="d-flex gap-3 ">
                  <div className="form-field">
                    <label htmlFor="city">City</label>
                    <Field
                      type="text"
                      id="city"
                      name="city"
                      className="form-input"
                      placeholder="Enter your City"
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-field">
                    <label htmlFor="state">State</label>
                    <Field
                      type="text"
                      id="state"
                      name="state"
                      className="form-input"
                      placeholder="Enter your State"
                    />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label htmlFor="conversation_type">
                    preferred conversation type
                  </label>
                  <div className="d-flex gap-3 mt-2">
                    <div className="form-check">
                      <Field
                        type="radio"
                        name="conversation_type"
                        value="call"
                        id="conversationTypeCall"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="conversationTypeCall"
                        className="form-check-label"
                      >
                        Telephonic
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        type="radio"
                        name="conversation_type"
                        value="email"
                        id="conversationTypeEmail"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="conversationTypeEmail"
                        className="form-check-label"
                      >
                        Email
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-field">
                  <div className="form-check">
                    <Field
                      type="checkbox"
                      id="accept_terms"
                      name="accept_terms"
                      className="form-check-input"
                    />
                    <label
                      htmlFor="accept_terms"
                      className="form-check-label copyright-text"
                    >
                      I agree,understand and accept that i have read Terms of
                      services, website privacy policy and had also gone through
                      FAQ section Thoroughly and i do not have any doubt
                      regarding any plans and services provided by S & S
                      Investmens.
                    </label>
                    <ErrorMessage
                      name="accept_terms"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="btn_div mt-4">
                    <CustomSubmitButton
                      type="submit"
                      title="submit"
                      color="#0098DA"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
