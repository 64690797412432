import React, { useEffect, useState } from "react";
import "./MarketBuzz.css";
import planHeroBg from "../../../assets/graphic/bg-graphic/plan-hero-banner-bg.svg";
import axios from "axios";
import Loading from "../../../components/loading/loading";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { apiClient } from "../../../api";
import LogoImg from "../../../assets/logo/blog_img_logo.jpg";

const MarketBuzz = () => {
  const [marketNewsData, setMarketNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchMarketBuzzApi = async () => {
      const response = await apiClient.get(`/get-market-buzz`);
      if (response.status === 200) {
        const jsonString = response?.data?.data[0]?.marketBuzz;
        const parsedData = jsonString ? JSON.parse(jsonString) : "";
        setIsLoading(false);
        setMarketNewsData(parsedData);
      }
    };
    fetchMarketBuzzApi();
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="main_container section-padding">
        <div className="graphic_hero_bg">
          <img src={planHeroBg} alt="planHeroBg" />
        </div>
        <div className="d-flex flex-column text-center">
          <h2 className="section-title">Market buzz</h2>
          <p className="section-sub-title">
            Unlock the pulse of the financial world with market insights.
          </p>
        </div>
        <div className="blog-card-container">
          {isLoading ? (
            <Loading />
          ) : (
            marketNewsData &&
            marketNewsData.length > 0 &&
            marketNewsData?.map(
              ({
                url,
                banner_image,
                title,
                authors,
                time_published,
                source,
                summary,
              }) => {
                return (
                  url &&
                  banner_image &&
                  title &&
                  authors &&
                  time_published &&
                  source &&
                  summary && (
                    <div className="blog-card">
                      <div>
                        <div className="blog-img-div">
                          <a href={url} target="_blank" rel="noreferrer">
                            <img
                              src={banner_image}
                              alt="blog-img"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = LogoImg;
                              }}
                            />
                          </a>
                        </div>
                        <ul class="img-footer">
                          <li class="small-body-font">Author</li>
                          <li class="small-body-font">{authors[0]}</li>
                        </ul>
                      </div>
                      <div class="blog-text-section">
                        <h2 class="element-title-2">
                          <a
                            className="text-decoration-none text-dark"
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {title?.length > 80
                              ? `${title?.slice(0, 80)}...`
                              : title}
                          </a>
                        </h2>
                        <p className="body-font blog-description">
                          {summary?.length > 80
                            ? `${summary?.slice(0, 82)}...`
                            : summary}
                        </p>
                        <footer class="blog-text-section-footer body-font">
                          <span>{source}</span>
                          <span className="text-lowercase ">
                            {moment(time_published).fromNow()}
                          </span>
                        </footer>
                      </div>
                    </div>
                  )
                );
              }
            )
          )}
        </div>
        {/* <div className="blog-card-container">
          {isLoading ? (
            <Loading />
          ) : (
            newsData.map(
              ({
                url,
                urlToImage,
                title,
                author,
                publishedAt,
                source,
                content,
                description,
              }) => {
                return (
                  (url,
                  urlToImage,
                  title,
                  author,
                  publishedAt,
                  source,
                  content,
                  description) && (
                    <div className="blog-card">
                      <div>
                        <div className="blog-img-div">
                          <a href={url} target="_blank" rel="noreferrer">
                            <img src={urlToImage} alt="blog-img" />
                          </a>
                        </div>
                        <ul class="img-footer">
                          <li class="small-body-font">Author</li>
                          <li class="small-body-font">{author}</li>
                        </ul>
                      </div>
                      <div class="blog-text-section">
                        <h2 class="element-title-2">
                          <a
                            className="text-decoration-none text-dark"
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {title?.length > 80
                              ? `${title?.slice(0, 80)}...`
                              : title}
                          </a>
                        </h2>
                        <p className="body-font blog-description">
                          {description?.length > 80
                            ? `${description?.slice(0, 82)}...`
                            : description}
                        </p>
                        <footer class="blog-text-section-footer body-font">
                          <span>{source?.name}</span>
                          <span className="text-lowercase ">
                            {moment(publishedAt).fromNow()}
                          </span>
                        </footer>
                      </div>
                    </div>
                  )
                );
              }
            )
          )}
        </div> */}
      </div>
    </div>
  );
};

export default MarketBuzz;
