import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isLogin } = useSelector((state) => state.loginData);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return isLogin ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
