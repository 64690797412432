// reducers.js

import {
  FETCH_PLAN_TYPES_REQUEST,
  FETCH_PLAN_TYPES_SUCCESS,
  FETCH_PLAN_TYPES_FAILURE,
  FETCH_SUB_PLAN_TYPES_REQUEST,
  FETCH_SUB_PLAN_TYPES_SUCCESS,
  FETCH_SUB_PLAN_TYPES_FAILURE,
} from "./Plans.action.types";

const initialState = {
  planTypes: [],
  subPlanTypes: [],
  loading: false,
  error: null,
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_TYPES_REQUEST:
    case FETCH_SUB_PLAN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PLAN_TYPES_SUCCESS:
      return {
        ...state,
        planTypes: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_SUB_PLAN_TYPES_SUCCESS:
      return {
        ...state,
        subPlanTypes: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_PLAN_TYPES_FAILURE:
    case FETCH_SUB_PLAN_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default planReducer;
