import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { apiClient } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loading from "../../components/loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DownloadPdf from "../../components/DownLoadPdf/DownloadPdf";
import { PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { getLogout } from "../../redux-store/Login/Login.action";

const MyAccount = () => {
  const [clientDetails, setClientDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: getLoginData } = useSelector((state) => state.loginData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterUserDetail = clientDetails[0]?.user_details;
  const filterBankDetail = clientDetails[0]?.bank_details;
  const filterPlanDetail = clientDetails[0]?.plan_details;
  const filterPayoutDetail = clientDetails[0]?.payout_details;

  // plan status
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    // Get the current date
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    // Set the expiration date (replace this with your actual expiration date)
    const expirationDate = moment(filterPlanDetail?.[0]?.end_date).format(
      "YYYY-MM-DD"
    );

    // Check if the current date is before the expiration date
    if (currentDate < expirationDate) {
      // If not expired, update the checkbox status
      setChecked(true);
    }
  }, [filterPlanDetail]);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await apiClient.get("/get-client-details", {
          params: { userid: getLoginData?.data?.id },
          headers: {
            Authorization: `Bearer ${getLoginData?.token}`,
          },
        });

        if (response.status === 200) {
          setIsLoading(false);
          setClientDetails(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/login", { replace: true });
        dispatch(getLogout());
      }
    };

    getUserDetails();
  }, [dispatch, getLoginData?.data?.id, getLoginData.token, navigate]);

  // download pdf
  // const handleDownload = () => {
  //   // Create a new element for the PDF component
  //   const pdfComponent = document.createElement("div");
  //   pdfComponent.className = "pdf-component";

  //   // Append the PDF component to the body
  //   document.body.appendChild(pdfComponent);
  //   ReactDOM.render(
  //     <DownloadPdf
  //       receipt_no={clientDetails?.[0]?.receipt_no}
  //       email={clientDetails[0]?.email}
  //       filterUserDetail={filterUserDetail?.[0]}
  //       filterBankDetail={filterBankDetail?.[0]}
  //       filterPlanDetail={filterPlanDetail?.[0]}
  //     />,
  //     pdfComponent
  //   );

  //   // Capture the PDF component using html2canvas
  //   html2canvas(pdfComponent).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait", // Change orientation to portrait or landscape based on your preference
  //     });
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("Payout-detail.pdf");

  //     // Remove the pdfComponent and its content from the DOM
  //     ReactDOM.unmountComponentAtNode(pdfComponent);
  //     pdfComponent.remove();
  //   });
  // };

  const handleDownload = () => {
    const pdfComponent = document.createElement("div");
    pdfComponent.className = "pdf-component";
    document.body.appendChild(pdfComponent);
    pdfComponent.style.width = "100%";
    pdfComponent.style.height = "100%";

    ReactDOM.render(
      <DownloadPdf
        receipt_no={clientDetails?.[0]?.receipt_no}
        email={clientDetails[0]?.email}
        filterUserDetail={filterUserDetail?.[0]}
        filterBankDetail={filterBankDetail?.[0]}
        filterPlanDetail={filterPlanDetail?.[0]}
      />,
      pdfComponent
    );

    html2canvas(pdfComponent).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      pdf.save("Receipt.pdf");

      ReactDOM.unmountComponentAtNode(pdfComponent);
      pdfComponent.remove();
    });
  };

  return (
    <div className="overflow-hidden">
      <div className="main_container section-padding">
        <div className="d-flex flex-column text-center">
          <h2 className="section-title">My Account</h2>
          <p className="section-sub-title">securing your financial freedom</p>
        </div>
        <div class="container">
          <div class="row body-font">
            <div class="col-12">
              {isLoading ? (
                <Loading />
              ) : (
                <form class="file-upload">
                  {/* personal detail */}
                  <div class="row mb-5 gx-5">
                    <div class="col-xxl-12 mb-5 mb-xxl-0">
                      <div class="bg-secondary-soft">
                        {filterUserDetail?.map((item) => (
                          <div class="row g-3">
                            <div className="d-flex justify-content-between ">
                              <h4 class="mt-0 ">Personal detail</h4>
                              <div className="checkbox-wrapper-5 d-flex justify-content-center  align-items-center gap-3 ">
                                <span>Plan status</span>
                                <div className="check">
                                  <input
                                    id="check-5"
                                    type="checkbox"
                                    checked={isChecked}
                                    readOnly
                                  />
                                  <label htmlFor="check-5"></label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Receipt No. </label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="First name"
                                value={clientDetails[0]?.receipt_no}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Full Name </label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Last name"
                                value={item?.full_name}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Email id </label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={clientDetails[0]?.email}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Contact No.</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.contact_no}
                              />
                            </div>
                            <div class="col-md-6">
                              <label for="inputEmail4" class="form-label">
                                Plan Detail
                              </label>
                              <input
                                disabled
                                type="email"
                                class="form-control"
                                id="inputEmail4"
                                value={item?.plan_details}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">State</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.state}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">City</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.city}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Profession</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.profession}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">PAN no.</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.pan_no}
                              />
                            </div>
                            <div class="col-md-6">
                              <label class="form-label">Aadhar No.</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                aria-label="Phone number"
                                value={item?.aadhar_no}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* plan Detail */}
                  {filterPlanDetail && filterPlanDetail?.length > 0 && (
                    <div class="row mb-5 gx-5">
                      <div class="col-xxl-12 mb-5 mb-xxl-0">
                        <div class="bg-secondary-soft">
                          {filterPlanDetail?.map((item) => (
                            <div class="row g-3">
                              <h4 class="mt-0 ">Plan detail</h4>
                              <div class="col-md-6">
                                <label class="form-label">Reciept No</label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="Reciept no"
                                  value={clientDetails[0]?.receipt_no}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">Plan Name </label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="Plan name"
                                  value={item?.plan_name}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">interst rate </label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="interst rate"
                                  value={item?.interest_rate}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">Start date</label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="start date"
                                  value={moment(item?.start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </div>
                              <div class="col-md-6">
                                <label for="inputEmail4" class="form-label">
                                  End date
                                </label>
                                <input
                                  disabled
                                  type="email"
                                  class="form-control"
                                  id="inputEmail4"
                                  value={moment(item?.end_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">
                                  Amount invested
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  value={item?.amount_invested}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">
                                  Interst rate/financial year
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="Phone number"
                                  value={item?.ir_fy}
                                />
                              </div>
                              <div class="col-md-6">
                                <label class="form-label">
                                  Plan duration in Day
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  aria-label="Phone number"
                                  value={item?.plan_duration}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* bank detail */}
                  {filterBankDetail && filterBankDetail?.length > 0 && (
                    <div class="row mb-5 gx-5">
                      <div class="col-xxl-12 mb-5 mb-xxl-0">
                        <div class="bg-secondary-soft">
                          <div class="row g-3">
                            <h4 class="mt-0 ">Bank detail</h4>
                            {filterBankDetail?.map((item) => (
                              <>
                                {" "}
                                <div class="col-md-6">
                                  <label class="form-label">Bank Name</label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Reciept no"
                                    value={item?.bank_name}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">branch Name </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Plan name"
                                    value={item?.branch_name}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">IFSC code </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="interst rate"
                                    value={item?.ifsc}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">MICR code</label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="start date"
                                    value={item?.micr}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label for="inputEmail4" class="form-label">
                                    Transaction Done
                                    (Cheque/RTGS/IMPS/NEFT/QR/UPI/ONLINE)
                                  </label>
                                  <input
                                    disabled
                                    type="email"
                                    class="form-control"
                                    id="inputEmail4"
                                    value={item?.transaction_type}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Transaction Reference No. (Cheque No./Ref
                                    ID,etc.)
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    value={item?.reference_no}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Transaction Date
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Phone number"
                                    value={moment(
                                      item?.transaction_date
                                    ).format("DD-MM-YYYY")}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Reflection Date/ Start Date
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Phone number"
                                    value={moment(item?.reflection_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* payout detail  */}
                  {filterPayoutDetail && filterPayoutDetail?.length > 0 && (
                    <div class="row mb-5 gx-5">
                      <div class="col-xxl-12 mb-5 mb-xxl-0">
                        <div class="bg-secondary-soft">
                          <div class="row g-3">
                            <div className="d-flex justify-content-between ">
                              <h4 class="mt-0 ">Payout detail</h4>
                              <span
                                style={{ cursor: "pointer" }}
                                className="d-none d-sm-flex body-font d-flex align-items-center gap-2 "
                                onClick={handleDownload}
                              >
                                <FontAwesomeIcon
                                  icon={faCloudArrowDown}
                                  color="#0098DA"
                                />
                                download receipt
                              </span>
                            </div>
                            {filterPayoutDetail?.map((item) => (
                              <div className="row g-3 mt-5">
                                <h2 className="element-title-2 text-decoration-underline ">
                                  {item?.year} year
                                </h2>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    {item?.year} Interest Amount (Before TDS)
                                  </label>

                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Reciept no"
                                    value={item?.interest_amount}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    TDS Deducted from Interest Amount
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="Plan name"
                                    value={item?.tds_deducted}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Final Interest Amount [After TDS Final
                                    Amount]
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="interst rate"
                                    value={item?.final_interest}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Maturity Amount [According to Plan]
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    aria-label="start date"
                                    value={item?.maturity_amount}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label for="inputEmail4" class="form-label">
                                    Credit Date [Credit Date in Clients Account]
                                  </label>
                                  <input
                                    disabled
                                    type="email"
                                    class="form-control"
                                    id="inputEmail4"
                                    value={moment(item?.credit_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label class="form-label">
                                    Transaction No. [Transaction reference no.]
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    value={item?.transaction_no}
                                  />
                                </div>
                                {item?.year === "final" && (
                                  <div class="col-md-6">
                                    <label class="form-label">
                                      Total Amount Invested
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      class="form-control"
                                      value={item?.total_amount_invested}
                                    />
                                  </div>
                                )}
                                {item?.year === "final" && (
                                  <div class="col-md-6">
                                    <label class="form-label">
                                      Total Interest Amount [Before TDS
                                      Deduction]
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      class="form-control"
                                      value={item?.total_interest_amount}
                                    />
                                  </div>
                                )}
                                {item?.year === "final" && (
                                  <div class="col-md-6">
                                    <label class="form-label">
                                      Final Interest Amount [After TDS
                                      Deduction]
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      class="form-control"
                                      value={item?.final_interest_amount}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
