export const TestominalData = [
  {
    id: 1,
    customerName: "KIRTI S",
    review:
      "I've been a client of S and S Investments since inception, and they've consistently provided excellent service. Their proactive approach to managing my Investments gives me confidence in my financial future.",
  },
  {
    id: 2,
    customerName: "R shah",
    review:
      "S and S Investments has a knowledgeable team that stays ahead of market trends. They've consistently delivered solid interest rates on my investments, and their services are impeccable",
  },
  {
    id: 3,
    customerName: "Z ADATIYA",
    review:
      "S and S Investments impressed me with their prompt and accurate investment advice. They're always accessible, responsive to my queries, and have helped me achieve my financial goals.",
  },
  {
    id: 4,
    customerName: "VEDANT D",
    review:
      "S and S Investments exceeded my expectations. Outstanding service!",
  },
  {
    id: 5,
    customerName: "PRATIK U",
    review:
      "I appreciate the dedication and attention to detail from S and S Investments. Their commitment to client success sets them apart, ensuring a collaborative approach that maximizes interest rates and minimizes risks.",
  },
  {
    id: 6,
    customerName: "ROHIT G",
    review:
      "Working with S and S Investments has been a game-changer for my financial well-being. Their approach is proactive, strategic, and dedicated to helping me navigate the complexities of the investment world.",
  },
  {
    id: 7,
    customerName: "PAREKH p",
    review:
      "Choosing S and S Investments was one of the best decisions I've made for my financial future. Their expertise and guidance have significantly grown my investments, and I appreciate their proactive communication.",
  },
  {
    id: 8,
    customerName: "S SAHU",
    review: "I've had a wonderful experience with S and S Investments.",
  },
  {
    id: 9,
    customerName: "B SWAR",
    review:
      "S and S Investments has made investing easy and profitable for me. Their team is attentive, and they've crafted a well fixed interest plan’s that has shown consistent growth.",
  },
  {
    id: 10,
    customerName: "R PURWAR",
    review:
      "I've been with S and S Investments for 2 years now, and their dedication to my financial success has never wavered. They continually adapt to market changes, ensuring my investments are secure and growing. ",
  },
  {
    id: 11,
    customerName: "P PURWAR",
    review:
      "S and S Investments has been my financial partner for almost 1.5 years. Their team is not just knowledgeable but also extremely professional. They have helped me make smart investment decisions, and I couldn't be happier with the results.",
  },
  {
    id: 12,
    customerName: "K AGRAWAL",
    review:
      "The team at S and S Investments is not just focused on making money; they are focused on protecting and growing your wealth. I'm grateful to have them as my financial advisors.",
  },
  {
    id: 13,
    customerName: "N Padia",
    review:
      "I've entrusted S and S Investments with my retirement savings, and they've consistently exceeded my expectations. Their strategic approach to investing is second to none.",
  },
  {
    id: 14,
    customerName: "ABHIJEET K",
    review:
      "What sets S and S Investments apart is their dedication to educating their clients. They take the time to explain their investment strategies and ensure you're comfortable with your choices.F",
  },
  {
    id: 15,
    customerName: "D THAKRE",
    review:
      "I've been a client of S and S Investments for past 2 years. Their consistency and commitment to my financial goals are why I continue to trust them with my investments.",
  },
  {
    id: 16,
    customerName: "M GUPTA",
    review:
      "The team at S and S Investments has a deep knowledge of financial markets and a keen eye for opportunities. I've watched my capital grow steadily under their guidance.",
  },
  {
    id: 17,
    customerName: "SAHIL S",
    review:
      "S and S Investments has not only grown my wealth but has also provided a sense of financial security. Their professionalism and transparency are top-notch.",
  },
  {
    id: 18,
    customerName: "KAILASH N",
    review:
      "I trust S and S Investments with my hard-earned money. Their transparency and strategic investment guidance have given me peace of mind and steady interest rates on my capitals.",
  },
  {
    id: 19,
    customerName: "B THACKER",
    review:
      "S and S Investments has made investing a breeze. Their expertise and commitment to client success shine through in every interaction. I highly recommend their services to anyone seeking financial growth.",
  },
  {
    id: 20,
    customerName: "H UPADHYAY",
    review:
      "S and S Investments has a strong track record of success, and I can attest to it. They're proactive, responsive, and highly knowledgeable. I trust them with my investments completely.",
  },
];
