import React, { useEffect, useState } from "react";
import BlueBannerImg from "../../assets/img/Home Page banner/banner2.png";
import { Calculator } from "../../components/RateOfInterst/Calculator";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import Testominals from "../../components/Testominals/Testominals";
import { FaqSection } from "../../components/Faq/FaqSection";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import moment from "moment/moment";
import { apiClient, getPlanTypeAPi } from "../../api";
import { useDispatch } from "react-redux";

const Home = () => {
  const [quoteState, setQuoteState] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncApi = async () => {
      try {
        const response = await apiClient.get(`/quote/get-quote`);
        if (response.status === 200) {
          setQuoteState(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    asyncApi();
    // fetch plan types
    getPlanTypeAPi(dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* hero sectionb start */}
      <section className="position-relative hero-section">
        <img src={BlueBannerImg} className="img-fluid" alt="hero-img" />

        <div className="hero-text-container-main">
          <div className="hero-text-container">
            <h1 className="display-font">Make your financial move.</h1>
          </div>
        </div>
      </section>
      {/* hero sectionb end */}

      {/* quote section start*/}
      <section class="quote-container section-margin-top ">
        <div class="quote-bg">
          <span className="leftQuoteIcon">
            <FontAwesomeIcon icon={faQuoteLeft} className="fa-2x" />
          </span>
          <figure class="text-center mb-0">
            <i class="fa-solid fa-quote-left"></i>
            <blockquote class="blockquote">
              <p class="pb-3 w-100 m-auto w-md-75">
                <i class="fas fa-quote-left fa-xs text-primary"></i>
                <span class="quote-text">
                  <p>{quoteState?.data?.Data}</p>
                </span>
              </p>
            </blockquote>
            <figcaption class="blockquote-footer body-font mb-0">
              {moment(Date()).format("Do MMM  YYYY")}
            </figcaption>
          </figure>
          <span className="rightQuoteIcon">
            <FontAwesomeIcon icon={faQuoteRight} className="fa-2x" />
          </span>
        </div>
      </section>
      {/* quote section  end*/}

      {/* return calaculator start*/}
      <div className="section-padding bg-white">
        <div className="text-center">
          <h2 className="section-title">Wealth Calculator</h2>
          <h3 className="section-sub-title">
            Calculate potential returns on your investment
          </h3>
        </div>
        <Calculator />
      </div>
      {/* return calaculator end*/}

      {/* testominal section start */}
      <div className="section-padding ">
        <div className="text-center">
          <h2 className="section-title">Reviews by our proud Clients</h2>
          <h3 className="section-sub-title ">
            We appreciate you to take a moment to read some wonderful Reviews by
            our proud and esteemed clients
          </h3>
        </div>
        <Testominals />
      </div>
      {/* testominal section end */}
      {/* Faq section start*/}
      <div className="section-padding  bg-white">
        <FaqSection />
      </div>
      {/* Faq section end*/}
    </>
  );
};

export default Home;
