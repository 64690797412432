// actions.js

import {
  FETCH_PLAN_TYPES_FAILURE,
  FETCH_PLAN_TYPES_REQUEST,
  FETCH_PLAN_TYPES_SUCCESS,
  FETCH_SUB_PLAN_TYPES_FAILURE,
  FETCH_SUB_PLAN_TYPES_REQUEST,
  FETCH_SUB_PLAN_TYPES_SUCCESS,
} from "./Plans.action.types";

export const fetchPlanTypesRequest = () => ({
  type: FETCH_PLAN_TYPES_REQUEST,
});

export const fetchPlanTypesSuccess = (planTypes) => ({
  type: FETCH_PLAN_TYPES_SUCCESS,
  payload: planTypes,
});

export const fetchPlanTypesFailure = (error) => ({
  type: FETCH_PLAN_TYPES_FAILURE,
  payload: error,
});

export const fetchSubPlanTypesRequest = () => ({
  type: FETCH_SUB_PLAN_TYPES_REQUEST,
});

export const fetchSubPlanTypesSuccess = (subPlanTypes) => ({
  type: FETCH_SUB_PLAN_TYPES_SUCCESS,
  payload: subPlanTypes,
});

export const fetchSubPlanTypesFailure = (error) => ({
  type: FETCH_SUB_PLAN_TYPES_FAILURE,
  payload: error,
});
