import React from "react";
import "./CustomButton.css";
import { NavLink, useNavigate } from "react-router-dom";

export const CustomButton = ({ title, to, color, textcolor }) => {
  const navigate = useNavigate();
  return (
    // <NavLink to={to} className="text-decoration-none">
    <button
      onClick={() => navigate(to)}
      className="blue-btn"
      style={{ backgroundColor: `${color}`, color: `${textcolor}` }}
    >
      {title}
    </button>
    // </NavLink>
  );
};
export const CustomSubmitButton = ({ type, title, color, textcolor }) => {
  return (
    <button
      type={`${type}`}
      className="blue-btn"
      style={{ backgroundColor: `${color}`, color: `${textcolor}` }}
    >
      {title}
    </button>
  );
};
// export const CustomGrayButton = ({ title, to, color }) => {
//   return (
//     <NavLink to={to} className="text-decoration-none">
//       <div className="blue-btn"  style={{ backgroundColor: `${color}` }}>{title}</div>
//     </NavLink>
//   );
// };
export const CustomSmallButton = ({ title, bgcolor }) => {
  return (
    <NavLink className="text-decoration-none">
      <div className="plan-btn" style={{ backgroundColor: `${bgcolor}` }}>
        {title}
      </div>
    </NavLink>
  );
};
