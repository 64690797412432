import React from "react";
import Logo from "../../assets/logo/S_S Investment.png";
import Signature from "../../assets/img/signature-bg.png";
import moment from "moment";
import "./DownloadPdf.css";

const DownloadPdf = ({
  receipt_no,
  email,
  filterUserDetail,
  filterBankDetail,
  filterPlanDetail,
}) => {
  const columnData = [
    {
      key: "Receipt Number:",
      value: receipt_no,
    },
    {
      key: "Received with Thanks From Ms. /Mr.:",
      value: filterUserDetail?.full_name,
    },
    { key: "The Sum of Rupees", value: filterPlanDetail?.amount_invested },
    {
      key: "By Cheque/NEFT/RTGS/UPI/QR/Online:",
      value: filterBankDetail?.transaction_type,
    },
    {
      key: "Cheque/Reference No./Transaction Id.:",
      value: filterBankDetail?.reference_no,
    },
    {
      key: "Dated:",
      value: moment(filterBankDetail?.transaction_date).format("DD-MM-YYYY"),
    },
    { key: "Bank Name", value: filterBankDetail?.bank_name },
    { key: "Plan Type", value: filterPlanDetail?.plan_name },
    {
      key: "Plan Interest Rate (%) / Financial Year",
      value: filterPlanDetail?.ir_fy,
    },
    {
      key: "Plan Start Date",
      value: moment(filterPlanDetail?.start_date).format("DD-MM-YYYY"),
    },
    {
      key: "Plan End Date",
      value: moment(filterPlanDetail?.end_date).format("DD-MM-YYYY"),
    },
    { key: "Plan Duration (in DAYS)", value: filterPlanDetail?.plan_duration },
    { key: "Client’s PAN No.", value: filterUserDetail?.pan_no },
    { key: "Client’s Aadhar No.", value: filterUserDetail?.aadhar_no },
    { key: "Client’s Contact No.", value: filterUserDetail?.contact_no },
    { key: "Client’s Email-ID", value: email },
  ];

  return (
    <div className="pdf-component d-flex flex-column justify-content-center ">
      <section className="position-relative  border border-sm-5 border-black  m-sm-5 p-sm-5">
        <div className="section-one">
          <img src={Logo} alt="watermark-logo" />
        </div>
        <div className="d-flex justify-content-center ">
          <img src={Logo} alt="logo" className="img-fluid" width={250} />
        </div>
        <p className="body-font fw-bold mt-2 text-center">
          RECEIPT CUM ACKNOWLEDGEMENT
        </p>
        <div className="d-sm-flex justify-content-center gap-3">
          <div className="d-none d-sm-flex flex-column justify-content-evenly  border border-success p-3 rounded-end-3 ">
            <h2 className="element-title">R</h2>
            <h2 className="element-title">E</h2>
            <h2 className="element-title">C</h2>
            <h2 className="element-title">E</h2>
            <h2 className="element-title">I</h2>
            <h2 className="element-title">P</h2>
            <h2 className="element-title">T</h2>
          </div>
          <div className="d-sm-flex justify-content-center p-3 w-100  ">
            <table class="table table-borderless ">
              <tbody className=" ">
                <tr>
                  <td className="fw-bold bg-transparent">
                    Date : {""}{" "}
                    {moment(filterPlanDetail?.start_date).format("DD-MM-YYYY")}
                  </td>
                  <td className="bg-transparent"></td>
                </tr>
                {columnData.map((item) => (
                  <tr className="body-font fw-bold ">
                    <td className="bg-transparent">{item.key}</td>
                    <td className="bg-transparent">
                      {item.value ? item.value : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-none d-sm-flex flex-column gap-4  justify-content-evenly border border-success p-3 rounded-start-3 ">
            <h2 className="element-title">R</h2>
            <h2 className="element-title">E</h2>
            <h2 className="element-title">C</h2>
            <h2 className="element-title">E</h2>
            <h2 className="element-title">I</h2>
            <h2 className="element-title">P</h2>
            <h2 className="element-title">T</h2>
          </div>
        </div>
      </section>

      {/* model  */}
      <section className=" position-relative border border-sm-5 border-black  m-sm-5 p-sm-5">
        <div className="d-sm-flex m-auto mt-sm-5  flex-column w-100 ">
          <div className="section-two">
            <img src={Logo} alt="watermark-logo" />
          </div>
          <p>DISCLAIMER/ I UNDERSTAND</p>
          <p>
            With this the Lender/Investor/User/Client AGREES and UNDERSTANDS
            that he or she is investing the capital as a LENDER in S & S
            Investments and will get FIXED INTEREST AMOUNT ANUALLY (AS Mentioned
            in the FAQ Section) (Depending on the Plan’s that the Lender has
            selected i.e. FIXED or CUSTOMIZED INTEREST RATES), and S & S
            Investments is BORROWING the Capital as a LOAN AMOUNT at the
            interest rates specified in the Plan (FIXED or CUSTOMIZED INTEREST
            RATES) Selected or Chosen by the Lender and provided by S & S
            Investments.
          </p>

          <ul>
            <li>
              I understand that the LENDER bear INTEREST RATE as per the
              SELECTED PLAN respectively per annum on the amount of initial
              capital. [Depends on the Plan Selected, Kindly go to FAQ Section
              thoroughly to understand the calculation of interest amounts and
              how it will be credited in Lenders bank account].
            </li>
            <li>
              I hereby declare that the information such as (Name, Contact
              Information, Bank Details, Payment Details, ID Proof’s, etc.)
              provided by me through Emails, Website or through any other types
              of communication media’s, and which is available and collected by
              S & S Investments for safe and secure financial transactions is
              true to the best of my knowledge and belief and nothing has been
              concealed therein. I understand that if the information given by
              me is proved false/not true, I will have to face the punishment as
              per the law.
            </li>
            <li>
              I allow and understands that the Information collected by S & S
              Investments is for only SAFE and SECURE financial transactions.
            </li>
            <li>
              I understand that the Plan START Date is the date on which the
              selected plan’s Amount is credited/Reflected in S & S Investments
              Account.
            </li>
            <li>
              I understand that the Plan END Date also known as Maturity Date of
              Plan is the date on which the Principal Amount and the FINAL
              Interest will be credited/Reflected in Lender’s/Clients Account.
              [Depends on the Plan Selected].
            </li>
            <li>
              I understand that the Standard TDS Deduction will be applied on
              INTEREST AMOUNT.
            </li>
            <li>
              I understand and does not have any doubts regarding the
              calculation of Plan duration.
            </li>
            <li>
              I understand and does not have any doubts regarding the
              calculation of interest amount and how it will be credited in the
              lender’s account.
            </li>
            <li>
              I understood the PLAN clearly (with all the criteria for
              interested plan) prior to lending the capital.
            </li>
            <li>
              understand that the Plan started/activated once will not be able
              to PAUSE/STOPPED/ or CANCELED in any circumstances, Plan will
              start once the amount is CREDITED/REFELECTED in S & S Investments
              Account.
            </li>
            <li>
              I have read the FAQ Section thoroughly and understood and does not
              have any doubts regarding the details of Plans for investments.
            </li>
            <li>
              Kindly visit our website www.sandsinvestments.co.in for more
              information and detailed FAQ’s
            </li>
            <li>
              S & S Investments reserves and holds all the rights for the
              CHANGES and UPDATES in the Policies, Plans and Conditions or any
              other Criteria’s for various investment Plans.
            </li>
          </ul>
          <p>
            <b> NOTE:</b> {""}The LENDER (for this particular plan) bear
            interest at the rate of {filterPlanDetail?.ir_fy}% percent
            respectively (fixed rate) per annum on the amount of initial
            capital. Interest will be credited ANUALLY at the END OF FINANCIAL
            YEAR)-(MAX BY-5 APRIL) to the bank account of the lender/investor
            and the LAST interest will be payable on maturity along with the
            principal capital (Whichever is applicable according to the plan
            selected).
          </p>

          <div className="receipt-footer small-body-font">
            <div className="grid-1 d-sm-flex  flex-column justify-content-between ">
              <div>
                <p className="fw-bold m-0">Drop us query on:</p>
                <p className="text-lowercase">info@sandsinvestments.co.in</p>
              </div>
              <div>
                {" "}
                <p className="fw-bold m-0">Visit our website for more info</p>
                <p className="text-lowercase">https://sandsinvestments.co.in</p>
              </div>
            </div>
            <div className=" grid-2 d-flex flex-column justify-content-between ">
              <p className="fw-bold m-0">Client's Signature:-</p>
              {/* <img src="" width={150} alt="signature" /> */}
              <p>
                Date:-{" "}
                {moment(filterPlanDetail?.start_date).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="grid-3 d-flex flex-column justify-content-between  ">
              <p className="fw-bold m-0">For , S & S Investments:-</p>
              <img src={Signature} width={150} alt="signature" />
              <p>
                Date:-{" "}
                {moment(filterPlanDetail?.start_date).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DownloadPdf;
