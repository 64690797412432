import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TestominalData } from "../../fixed-data/testominalData";

// export const createAvtar = (fullName) => {
//   const initials = fullName
//     .split(" ")
//     .map((word) => word.slice(0, 2))
//     .join("")
//     .toUpperCase();
//   return initials;
// };
export const createAvtar = (fullName) => {
  const names = fullName.split(" ");
  const firstNameInitial = names[0].charAt(0);
  const lastNameInitial = names.length > 1 ? names[names.length - 1].charAt(0) : '';

  return (firstNameInitial + lastNameInitial).toUpperCase();
};

const Testominals = () => {
  function getRandomColor() {
    let color;
    do {
      color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    } while (isColorTooCloseToWhite(color));

    const textColor = getTextColorForBackground(color);

    return {
      backgroundColor: color,
      color: textColor,
    };
  }

  function isColorTooCloseToWhite(color) {
    // Convert hex color to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Check if the color is too close to white
    return r + g + b > 500; // You can adjust the threshold as needed
  }

  function getTextColorForBackground(color) {
    return isColorTooCloseToWhite(color) ? "black" : "white";
  }

  return (
    <div
      id="carouselExampleControls"
      class="carousel slide text-center section-margin-top"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        {TestominalData.map((item) => (
          <div
            class={item.id === 1 ? "carousel-item active" : "carousel-item "}
          >
            <div class="avatarDiv" style={getRandomColor()}>
              {createAvtar(item.customerName)}
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <h5 class="mb-3 mb-lg-4 element-title">{item.customerName}</h5>
                <p class="quote-text-testominal">{item.review}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* next and prev button section  */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-icon-background">
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-icon-background">
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </button>
    </div>
  );
};
export default Testominals;
