import React, { useEffect } from "react";
import visionImg from "../../assets/img/svg/vision.svg";
import vision2Img from "../../assets/img/svg/vision2.svg";
import SameerImg from "../../assets/img/founder/sameer.jpg";
import KeyurImg from "../../assets/img/founder/keyur.jpg";
import Aboutus from "../../assets/img/svg/about-us.svg";
import "./About.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" section-padding page-wrapper">
      {/* about us main banner  */}
      <div className="d-flex flex-column text-center">
        <h2 className="section-title">About us</h2>
        <p className="section-sub-title">
          Small team, big dreams – making waves in every step.
        </p>
      </div>
      <div className="about-main-banner-container">
        <div className="about-banner-left">
          <p className="body-font mt-2">
            S & S Investments is a reputable and forward-thinking investment
            company dedicated to providing exceptional financial services and
            tailored investment solutions to our clients. With a proven track
            record of success, established officially in the year of 2021 we
            strive to help our clients achieve their financial goals and secure
            a prosperous future.
          </p>
          <p className="body-font mt-2">
            {" "}
            At S & S Investments, we believe in transparency, integrity, and a
            client-centric approach. We prioritize open communication and foster
            strong relationships with our clients, built on trust and
            reliability. Our comprehensive range of investment services includes
            fixed & customized interest rate plans, financial planning,
            portfolio management, real estate, asset allocation, or alternative
            investments and market analysis. We diligently navigate the dynamic
            financial landscape to optimize returns and manage risk effectively.
          </p>
          <p className="body-font mt-2">
            As our Tagline quotes LET’S GROW TOGETHER, here at S & S Investments
            we are not just an investment firm; we're a trusted partner on the
            road to financial well-being. With a forward-thinking approach and a
            relentless pursuit of excellence, we are committed to guiding our
            clients towards a prosperous and secure financial future.
          </p>
        </div>
        <div className="about-banner-right">
          <div className="img-section">
            <img
              src={Aboutus}
              className="img-size mt-12 md-mt-16 xl-mt-0 img-fluid"
              alt="Teampicture"
            />
          </div>
          <div className="rounded-circle bg-primary bg-opacity-25 position-absolute top-0 xl-top-0 end-0 md-end-0"></div>
          <div className="rounded-circle bg-success bg-opacity-3 position-absolute bottom-0 md-bottom-0 xl-bottom-0 start-0 md-start-0"></div>
        </div>
      </div>

      {/* About us  section */}
      {/* <div className="about-container">
        <div className="section-title m-auto">About us</div>
        <span className="horizontal-rule"></span>

        <div className="about-us-div">
          <div className="img-section">
            <img
              src={Aboutus}
              className="img-fluid img-size"
              alt="Teampicture"
            />
          </div>
          <div className="mt-3">
            <p className="body-font mt-2">
              At S & S Investments, we believe in transparency, integrity,
              and a client- centric approach. We prioritize open communication
              and foster strong relationships with our clients, built on trust
              and reliability. Our comprehensive range of investment services
              includes fixed&customized interest rate plans, financial
              planning, portfolio management, real estate, asset allocation, or
              alternative investments and market analysis. We diligently
              navigate the dynamic financial landscape to optimize returns and
              manage risk effectively.
            </p>
            <p className="body-font mt-2">
              As our Tagline quotes LET’S GROW TOGETHER, here at S & S
              Investments we are not just an investment firm; we&#39;re a
              trusted partner on the road to financial well-being. With a
              forward-thinking approach and a relentless pursuit of excellence,
              we are committed to guiding our clients towards a prosperous and
              secure financial future.
            </p>
          </div>
        </div>
      </div> */}

      {/* our vision section */}
      <div className="about-container">
        <div className="section-title m-auto">Our vision</div>
        <span className="horizontal-rule"></span>
        <div className="iner-container">
          <div className="first-vision-div">
            <div>
              <div className="img-section">
                <img
                  src={visionImg}
                  className="img-fluid img-size"
                  alt="Teampicture"
                />
              </div>
              <div className="rounded-circle bg-primary bg-opacity-25 position-absolute top-0 xl-top-0 end-0 md-end-0"></div>
              <div className="rounded-circle bg-success bg-opacity-3 position-absolute bottom-0 md-bottom-0 xl-bottom-0 start-0 md-start-0"></div>
            </div>
            <div className="mt-3">
              <p className="body-font mt-2">
                Our commitment extends beyond financial success. We envision a
                world where our investments contribute to sustainable
                development, environmental responsibility, and social
                well-being. We seek to make a positive impact in the communities
                we serve, driving sustainable growth and creating lasting value.
              </p>
              <p className="body-font mt-2">
                Together, we envision a future where S & S Investments is
                synonymous with prosperity, trust, and a steadfast commitment to
                the well-being of our clients, employees, and society at
                large.&quot;
              </p>
              <p className="body-font mt-2">Come “LET’S GROW TOGETHER”.</p>
            </div>
          </div>

          <div className="second-vision-div">
            <div>
              <div>
                <div className="mt-3">
                  <p className="body-font mt-2">
                    At S & S Investments, we envision a future where financial
                    growth and prosperity are accessible to all. Our vision is
                    to be the beacon of trust and excellence in the investment
                    industry, empowering individuals and businesses to achieve
                    their financial goals and secure a brighter tomorrow.
                  </p>
                  <p className="body-font mt-2">
                    We strive to create a nurturing and innovative environment,
                    fostering a culture of integrity, teamwork, and continuous
                    learning. Through strategic investments and prudent
                    financial management, we aim to optimize interest rates for
                    our clients while minimizing risk.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="img-section">
                <img
                  src={vision2Img}
                  className="img-fluid img-size"
                  alt="Teampicture"
                />
              </div>
              <div className="rounded-circle bg-primary bg-opacity-25 position-absolute top-0 xl-top-0 end-0 md-end-0"></div>
              <div className="rounded-circle bg-success bg-opacity-3 position-absolute bottom-0 md-bottom-0 xl-bottom-0 start-0 md-start-0"></div>
            </div>
          </div>
        </div>
      </div>

      {/* founder section for destop */}
      <div className="founders-container section-padding-bottom">
        <div className="section-title m-auto">Founders</div>
        <span className="horizontal-rule"></span>
        <div className="comman-x-card section-margin-top">
          <div className="d-flex">
            <span className="element-title">
              Introducing the Visionaries and the Founders behind S & S
              Investments
            </span>
          </div>
          <div className="about-us-container">
            <div className="d-flex flex-column  gap-sm-3 ">
              <p className="body-font">
                Meet Mr. Keyur J. Shah and Mr. Sameer J. Somaiya, the dynamic
                duo driving the success of S & S Investments. With a shared
                passion for financial excellence and a wealth of experience in
                the investment world, Mr. Keyur and Mr. Sameer have come
                together to create a formidable force in the industry.
              </p>

              <p className="body-font">
                Mr. Sameer, a seasoned financial strategist, has spent over a
                decade mastering the intricacies of investment portfolios. His
                sharp analytical mind, coupled with a deep understanding of
                market trends, has consistently delivered impressive returns for
                his clients. More to that Mr. Sameer is a NISM Certified &
                SEBI’S Registered Associated Person for Mutual Fund
                Distributors, Portfolio Management Services and for Equity
                Derivatives, Also his expertise in Real Estate Business gives
                his clients an opportunity to get equitable returns over the
                period, Mr. Sameer's commitment to sound financial principles
                and his unwavering dedication to his clients' financial goals
                make him an invaluable asset to S & S Investments.
              </p>
            </div>
            <div className="founder-img-container">
              <div className="founder-card">
                <div className="founder-card-front">
                  <img className="img-fluid" src={SameerImg} alt="Avatar" />
                </div>
              </div>
              <div class="founder-details">
                <div class="founder-info">
                  <div class="element-title">Sameer Somaiya</div>
                  <div class="body-font">Founder</div>
                </div>
                <p className="small-body-font">
                  National Institute of Securities Market, CA Intermediate ICAI
                </p>
              </div>
            </div>
          </div>
          <div className="about-us-container-2">
            <div className="founder-img-container">
              <div className="founder-card">
                <div className="founder-card-front">
                  <img className="img-fluid" src={KeyurImg} alt="Avatar" />
                </div>
              </div>
              <div class="founder-details">
                <div class="founder-info">
                  <div class="element-title">Keyur Shah</div>
                  <div class="body-font">Founder</div>
                </div>
                <p className="small-body-font">
                  M.Tech in Computer Science, Nirma University
                </p>
              </div>
            </div>
            <div className="d-flex flex-column  gap-3">
              <p className="body-font">
                Mr. Keyur, on the other hand, brings a diverse skill set to the
                table. With a background in technology and a flair for
                innovation, Mr. Keyur has an innate ability to identify emerging
                investment opportunities in the digital age. His expertise in
                finances, Investment Products, Bonds and Securities leads to
                excellent returns and best interest for his clients. His
                Research based Analysis, forward-thinking approach and
                willingness to embrace new technologies have enabled S & S
                Investments to stay ahead of the curve in a rapidly evolving
                financial landscape.
              </p>
              <p className="body-font">
                Together, Mr. Keyur and Mr. Sameer form a powerhouse team that
                combines traditional financial wisdom with cutting-edge
                strategies. Their vision for S & S Investments is to generate
                stable and reliable active source of income for their Clients
                and empower them with the knowledge of different investments
                products, schemes and tools they need to achieve financial
                success and security in an ever-changing world. With Mr. Keyur
                and Mr. Sameer at the helm, S & S Investments is not just an
                investment company; it's a partner in your financial journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
