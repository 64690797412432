import React, { useEffect } from "react";
import { FaqMockData } from "./FaqMockData";

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const switchCaseFnc = (reValue) => {
    return (
      <div class="accordion" id="accordionExample">
        {reValue?.map((innerValue, index) => {
          switch (innerValue.question) {
            case innerValue.question:
              return (
                <div class="accordion-item mt-40">
                  <p class="accordion-header">
                    <button
                      class={
                        index
                          ? "accordion-button collapsed  body-font fw-bold text-dark"
                          : "accordion-button body-font fw-bold text-dark"
                      }
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#header-${innerValue.id}`}
                      aria-expanded="false"
                      aria-controls={`header-${innerValue.id}`}
                    >
                      {innerValue.question}
                    </button>
                  </p>
                  <div
                    id={`header-${innerValue.id}`}
                    class={
                      index
                        ? "accordion-collapse collapse body-font "
                        : "accordion-collapse collapse show body-font "
                    }
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {/* only for answer */}
                      {innerValue?.data?.answer?.map((item) => {
                        return <p>{item}</p>;
                      })}

                      {innerValue?.data?.items?.map((item) => (
                        <ul>
                          <li className="body-font">{item}</li>
                        </ul>
                      ))}
                      {/* only for section */}
                      {innerValue?.data?.section?.map((item) => (
                        <>
                          <p className="text-dark fw-bold mt-2 text-uppercase ">
                            {item?.title}
                          </p>
                          {item?.content?.map((item) => (
                            <p className="">{item}</p>
                          ))}
                          <p className="text-dark fw-bold mt-2 text-uppercase ">{item?.note}</p>
                        </>
                      ))}
                      {/* para with link */}
                      {innerValue?.data?.answerwithlink?.map((item) => (
                        <>
                          <span className="body-font ">
                            {item?.text} {""}
                          </span>
                          <a
                            className="text-decoration-none text-lowercase "
                            href={
                              item?.linktype === "mail"
                                ? `mailto:${item?.link}`
                                : item?.link
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.linktext}
                          </a>
                        </>
                      ))}
                      {/* para with link */}
                      {innerValue?.data?.answerwithlinklist?.map((item) => (
                        <>
                          <p className="body-font ">
                            {item?.text}
                            <a
                              className="text-decoration-none text-lowercase "
                              href={
                                item?.linktype === "mail"
                                  ? `${item?.link}`
                                  : item?.link
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.linktext}
                            </a>
                          </p>
                        </>
                      ))}
                      {/* only for Note */}
                      {innerValue?.data?.note && (
                        <p className="text-dark fw-bold mt-2 text-uppercase  ">
                          NOTE: {innerValue?.data?.note}
                        </p>
                      )}
                      {/* only for ul */}
                    </div>
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  };

  return (
    <div className="section-padding">
      <div className="d-flex flex-column text-center">
        <h2 className="section-title">frequently asked questions</h2>
        <p className="section-sub-title">Have any question ?</p>
      </div>

      <div className="faq-accordian-container">
        <div> {switchCaseFnc(FaqMockData)}</div>
      </div>
    </div>
  );
};

export default FaqPage;
