import React from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import "./Enquire.css";
import faqBg from "../../assets/graphic/bg-graphic/faq-bg.svg";

const EnquireDiv = () => {
  return (
    <div className="enquire-div-container">
      <h2 className="section-title">let’s grow together</h2>
      <div className="faq-bg-img">
        <img src={faqBg} alt="faq-bg" />
      </div>
      <p className="section-sub-title">secure your financial freedom</p>
      <div className="d-flex justify-content-center">
        <CustomButton
          title="Invest Now"
          color="#0098DA"
          to="/contact-us/form"
        />
      </div>
    </div>
  );
};

export default EnquireDiv;
