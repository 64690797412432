import React, { useEffect } from "react";
import planHeroBg from "../../assets/graphic/bg-graphic/plan-hero-banner-bg.svg";

export const TermsServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="section-padding">
        <div className="graphic_hero_bg">
          <img src={planHeroBg} alt="planHeroBg" />
        </div>

        <div className="d-flex flex-column text-center">
          <h2 className="section-title">terms of services</h2>
        </div>

        <div className="term-page-container ">
          <h2 className="element-title mb-20">I UNDERSTAND</h2>

          <p className="body-font mb-26">
            With this the Lender/Investor/User/Client AGREES and UNDERSTANDS
            that he or she is investing the capital as a LENDER in S & S
            Investments and will get FIXED INTEREST AMOUNT ANUALLY (AS Mentioned
            in the FAQ Section) (Depending on the Plan’s that the Lender has
            selected i.e. FIXED or CUSTOMIZED INTEREST RATES), and S & S
            Investments is BORROWING the Capital as a LOAN AMOUNT at the
            interest rates specified in the Plan (FIXED or CUSTOMIZED INTEREST
            RATES) Selected or Chosen by the Lender and provided by S & S
            Investments.
          </p>
          <ul>
            <li className="body-font mb-20">
              I understand that the LENDER bear INTEREST RATE as per the
              SELECTED PLAN respectively per annum on the amount of initial
              capital. [Depends on the Plan Selected, Kindly go to FAQ Section
              thoroughly to understand the calculation of interest amounts and
              how it will be credited in Lenders bank account].
            </li>
            <li className="body-font mb-20">
              I hereby declare that the information such as (Name, Contact
              Information, Bank Details, Payment Details, ID Proof’s, etc.)
              provided by me through Emails, Website or through any other types
              of communication media’s, and which is available and collected by
              S & S Investments for safe and secure financial transactions is
              true to the best of my knowledge and belief and nothing has been
              concealed therein. I understand that if the information given by
              me is proved false/not true, I will have to face the punishment as
              per the law.
            </li>
            <li className="body-font mb-20">
              I allow and understands that the Information collected by S & S
              Investments is for only SAFE and SECURE financial transactions.
            </li>

            <li className="body-font mb-20">
              I understand that the Plan START Date is the date on which the
              selected plan’s Amount is credited/Reflected in S & S Investments
              Account.
            </li>
            <li className="body-font mb-20">
              I understand that the Plan END Date also known as Maturity Date of
              Plan is the date on which the Principal Amount and the FINAL
              Interest will be credited/Reflected in Lender’s/Clients Account.
              [Depends on the Plan Selected].
            </li>

            <li className="body-font mb-20">
              I understand that the Standard TDS Deduction will be applied on
              INTEREST AMOUNT.
            </li>

            <li className="body-font mb-20">
              I understand and does not have any doubts regarding the
              calculation of Plan duration.
            </li>

            <li className="body-font mb-20">
              I understand and does not have any doubts regarding the
              calculation of interest amount and how it will be credited in the
              lender’s account.
            </li>

            <li className="body-font mb-20">
              I understood the PLAN clearly (with all the criteria for
              interested plan) prior to lending the capital.
            </li>

            <li className="body-font mb-20">
              I understand that the Plan started/activated once will not be able
              to PAUSE/STOPPED/ or CANCELED in any circumstances, Plan will
              start once the amount is CREDITED/REFELECTED in S & S Investments
              Account.
            </li>

            <li className="body-font mb-20">
              I have read the FAQ Section thoroughly and understood and does not
              have any doubts regarding the details of Plans for investments.
            </li>
            <li className="body-font mb-20">
              S & S Investments reserves and holds all the rights for the
              CHANGES and UPDATES in the Policies, Plans and Conditions or any
              other Criteria’s for various investment Plans.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
