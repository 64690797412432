import React from "react";
import FAQ from "../../assets/img/faq.svg";
import { CustomButton } from "../CustomButton/CustomButton";

export const FaqSection = () => {
  return (
    <div class="container">
      <div class="row gy-5 gy-lg-0 align-items-lg-center">
        <div class="col-12 col-lg-6">
          <div className="text-start">
            <h2 className="element-title">Frequently asked questions</h2>
          </div>
          <div className="img-section">
            <img
              class="img-fluid rounded img-size"
              loading="lazy"
              src={FAQ}
              alt=""
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row justify-content-xl-end">
            <div class="col-12 col-xl-11">
              <div class="accordion accordion-flush" id="accordionExample">
                <div class="accordion-item">
                  <p class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button body-font fw-bold "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is Borrowing Capital/Loans and how it works?
                    </button>
                  </p>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body body-font">
                      Loans are a common form of borrowing capital, where a
                      borrower (in our case we the S & S Investments) receives a
                      specified amount...
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <p class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed body-font fw-bold "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Where the borrowed capital is used and invested by S & S
                      Investments?
                    </button>
                  </p>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body body-font">
                      The specific use and investment of borrowed capital by S &
                      S Investments would depend on the company's...
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <p class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed body-font fw-bold "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What are the different Plans & services available for
                      Lending capital/Investments?
                    </button>
                  </p>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body body-font">
                      You can find all the detailed information of our Plans &
                      services from the Menu “Plans”, new plans and...
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center ">
                  <CustomButton title="view more" color="#0098DA" to="/faq" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
