/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import ComapanyLogo from "../../assets/logo/S_S Investment.png";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLogout } from "../../redux-store/Login/Login.action";
import { toast } from "react-toastify";
import { CustomButton } from "../CustomButton/CustomButton";
import { createAvtar } from "../Testominals/Testominals";

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isLogin, data } = useSelector((state) => state.loginData);
  const { planTypes } = useSelector((state) => state.planData);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = async () => {
    dispatch(getLogout());
    navigate("/login");
    toast.success("Successfully Logged Out", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleNavLinkClick = (path) => {
    // Close the drawer
    handleCloseNavMenu();
    navigate(path);
  };
  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ background: "#fff", borderBottom: "1px solid lightgray" }}
    >
      {/* <AppBar position="static"> */}
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-around",
            "@media(width < 576px)": {
              justifyContent: "space-between",
              p: "20px 0px",
            },
            p: "20px",
          }}
        >
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              sx={{ padding: 0 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon sx={{ width: "1.2em", height: "1.2em" }} />
            </IconButton>
            <Drawer
              PaperProps={{
                sx: { width: "50%" },
              }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <ul className="d-flex flex-column  gap-4 mt-4 list-unstyled m-0 ">
                <li className="nav-item dropdown">
                  <a
                    className="navigation-font navigation-font-gray dropdown-toggle"
                    href="#"
                    id="plansDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Plans & services
                  </a>
                  <div
                    className="dropdown-menu px-2 mt-3"
                    aria-labelledby="plansDropdown"
                  >
                    {planTypes?.data?.map((item) => (
                      <a
                        className="navigation-font-small p-2 text-capitalize"
                        onClick={() =>
                          handleNavLinkClick(
                            `plans-services/${item?.plan_name}`
                          )
                        }
                      >
                        {item?.plan_name}
                      </a>
                    ))}
                    {/* <a
                      className="navigation-font-small p-2"
                      onClick={() =>
                        handleNavLinkClick("/fixed-interst-rates-plan")
                      }
                    >
                      Fixed interest rates Plan
                    </a> */}
                    <a
                      className="navigation-font-small p-2"
                      to="customized-plan"
                      onClick={() => handleNavLinkClick("/customized-plan")}
                    >
                      Customized Plan
                    </a>
                    <a
                      className="navigation-font-small p-2"
                      onClick={() =>
                        handleNavLinkClick("/financial-consultant")
                      }
                    >
                      Financial Consultant
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="navigation-font navigation-font-gray"
                    onClick={() => handleNavLinkClick("/market-buzz")}
                  >
                    Market Buzz
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="navigation-font navigation-font-gray"
                    onClick={() => handleNavLinkClick("/about-us")}
                  >
                    About us
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="navigation-font navigation-font-gray"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </Drawer>
          </Box>
          <a className="navbar-brand" onClick={() => navigate("/")}>
            <img
              alt="logo"
              src={ComapanyLogo}
              width="150"
              className="d-inline-block align-top"
            />
          </a>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <ul className="d-flex justify-content-center   list-unstyled m-0 ">
              <li className="nav-item dropdown">
                <a
                  className="navigation-font navigation-font-gray dropdown-toggle"
                  href="#"
                  id="plansDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Plans & services
                </a>
                <div
                  className="dropdown-menu px-2 mt-3"
                  aria-labelledby="plansDropdown"
                >
                  {planTypes?.data?.map((item) => (
                    <a
                      className="navigation-font-small p-2 text-capitalize "
                      onClick={() =>
                        handleNavLinkClick(`plans-services/${item?.plan_name}`)
                      }
                    >
                      {item?.plan_name}
                    </a>
                  ))}
                  {/* <a
                    className="navigation-font-small p-2"
                    onClick={() => navigate("/fixed-interst-rates-plan")}
                  >
                    Fixed interest rates Plan
                  </a> */}
                  <a
                    className="navigation-font-small p-2"
                    onClick={() => navigate("/customized-plan")}
                  >
                    customized Plan
                  </a>
                  <a
                    className="navigation-font-small p-2"
                    onClick={() => navigate("/financial-consultant")}
                  >
                    Financial Consultant
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="navigation-font navigation-font-gray"
                  onClick={() => navigate("/market-buzz")}
                >
                  Market Buzz
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="navigation-font navigation-font-gray"
                  onClick={() => navigate("/about-us")}
                >
                  About us
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="navigation-font navigation-font-gray"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact us
                </a>
              </li>
            </ul>
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}> */}
          {!isLogin && (
            <CustomButton title="Login" to="/login" color="#0098DA" />
          )}
          {isLogin && (
            <div className="nav-item dropdown">
              <a
                className="navigation-font "
                to="#"
                id="companyDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="avatar rounded-circle border-1 border-black ">
                  { data?.data?.user_details && createAvtar(data?.data?.user_details[0]?.full_name || "")}
                </div>
              </a>
              <div
                className="dropdown-menu px-2 mt-3"
                aria-labelledby="plansDropdown"
              >
                <a
                  className="navigation-font-small p-2"
                  to="my-account"
                  onClick={() => navigate("/my-account")}
                >
                  My account
                </a>
                <a
                  className="navigation-font-small p-2"
                  onClick={() => navigate("/change-password")}
                >
                  change password
                </a>

                <div onClick={handleLogout}>
                  <a className="navigation-font-small p-2">Logout</a>
                </div>
              </div>
            </div>
          )}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem>
              <a
                className="navigation-font-small p-2"
                onClick={() => navigate("/my-account")}
              >
                My account
              </a>
            </MenuItem>

            <MenuItem>
              <a className="navigation-font-small p-2" to="change-password">
                change password
              </a>
            </MenuItem>

            <MenuItem>
              <div onClick={handleLogout}>
                <a className="navigation-font-small p-2">Logout</a>
              </div>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
