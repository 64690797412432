import axios from "axios";
import {
    fetchPlanTypesFailure,
  fetchPlanTypesSuccess,
  fetchSubPlanTypesFailure,
  fetchSubPlanTypesSuccess,
} from "../redux-store/Plans/Plans.action";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getPlanTypeAPi = async (dispatch) => {
  try {
    const response = await apiClient.get(`/get-plan-type`);

    if (response.status === 200) {
      return dispatch(fetchPlanTypesSuccess(response.data));
    }
  } catch (error) {
    throw dispatch(fetchPlanTypesFailure("fail to fetch plan "));
  }
};
export const getSubPlanTypeAPi = async (dispatch, planid) => {
  try {
    const response = await apiClient.get(`/get-sub-plan-type`, {
      params: { planid: planid },
    });

    if (response.status === 200) {
      return dispatch(fetchSubPlanTypesSuccess(response.data));
    }
  } catch (error) {
    throw dispatch(fetchSubPlanTypesFailure("fail to fetch sub plan "));
  }
};
