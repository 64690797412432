import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "./Login.action.types";

export const getLogin = (payload) => {
  return({
    type: LOGIN_SUCCESS,
    payload
  })
}
export const getLogout = () => {
  return({
    type: LOGOUT_SUCCESS
  })
}