import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./Login.action.types";

const initialState = {
  isLogin: false,
  data: [],
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        data: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLogin: false,
        data: [],
      };

    default:
      return state;
  }
};

export default LoginReducer;
