import React, { useEffect, useState } from "react";
import "./Calculator.css";
import { CustomButton } from "../CustomButton/CustomButton";
import investmentCalc from "../../assets/img/icon/chart.png";

export const Calculator = () => {
  const [sliderValue, setSliderValue] = useState({
    amount: 5,
    rate: 10,
    year: 2,
  });

  const [calculatedResult, setCalculatedResult] = useState({
    investmentAmount: 500000,
    interest: 100000,
    year: 2,
  });

  const handleCalculate = () => {
    const { amount, rate, year } = sliderValue;
    const imtrestAMount =
      (Number(amount) * 100000 * Number(rate) * Number(year)) / 100;

    setCalculatedResult({
      investmentAmount: amount * 100000,
      interest: imtrestAMount,
      year: year,
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSliderValue({ ...sliderValue, [name]: value });
  };

  const formatAmountWithComma = (amount) => {
    let x = amount.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div className="postion-relative  calc_container section-margin-top">
      <div className="">
        <div>
          <h2 className="element-title pb-3">Wealth Creation</h2>
        </div>

        <div class="calc-left-container">
          <div>
            <label for="customRange3" class="form-label body-font">
              Lending/investment Amount (in multiple of lakhs)
            </label>
            <p className="range-value-title">{sliderValue.amount} Lakhs</p>
            <input
              type="range"
              name="amount"
              min="1"
              max="100"
              step="1"
              value={sliderValue.amount}
              onChange={(e) => handleChange(e)}
            />
            <div className="input-bottom-span-div">
              <span className="calc-small-text">1 Lakh</span>
              <span className="calc-small-text">100 Lakhs</span>
            </div>
          </div>

          <div>
            <label for="customRange3" class="form-label body-font">
              Interst rate % (per annum)
            </label>
            <p className="range-value-title">{sliderValue.rate}</p>
            <input
              type="range"
              name="rate"
              //
              min="5"
              max="20"
              step="0.1"
              value={sliderValue.rate}
              onChange={(e) => handleChange(e)}
            />
            <div className="input-bottom-span-div">
              <span className="calc-small-text">5%</span>
              <span className="calc-small-text">20 %</span>
            </div>
          </div>
          <div>
            <label for="customRange3" class="form-label body-font">
              duration (no. of years)
            </label>
            <p className="range-value-title">{sliderValue.year}</p>
            <input
              type="range"
              name="year"
              id="myinput"
              min="1"
              max="5"
              step="1"
              value={sliderValue.year}
              onChange={(e) => handleChange(e)}
            />
            <div className="input-bottom-span-div">
              <span className="calc-small-text">1 yrs</span>
              <span className="calc-small-text">5 yrs</span>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center mx-auto"
          onClick={handleCalculate}
        >
          <CustomButton title="calculate" color="#0098DA" />
        </div>
      </div>
      <div class="">
        <div className="right-calc-main-div">
          <div class="right-calc-sec">
            <div className="d-flex m-auto">
              <img src={investmentCalc} alt="..." />
            </div>
            <div class="card-body">
              <h5 class="element-title pt-md-3">Wealth Summary</h5>
              <div class="container mt-3">
                <div class="row flex-sm-col  py-2 body-font">
                  <div class="col-sm-5 col-md-6 text-start">
                    Total Investment
                  </div>
                  <div class="col-sm-5 col-md-6 text-end">
                    ₹ {formatAmountWithComma(calculatedResult.investmentAmount)}
                  </div>
                </div>
                <div class="row py-2 body-font">
                  <div class="col-sm-5 col-md-6 text-start">Total duration</div>
                  <div class="col-sm-5 col-md-6 text-end">
                    {" "}
                    {formatAmountWithComma(calculatedResult.year)} year
                  </div>
                </div>
                <div class="row py-2 body-font">
                  <div class="col-sm-5 col-md-6 text-start">Total Interest</div>
                  <div class="col-sm-5 col-md-6 text-end">
                    {" "}
                    ₹ {formatAmountWithComma(calculatedResult.interest)}
                  </div>
                </div>
                <div class="row py-md-2 body-font mt-lg-2">
                  <div class="col-sm-5 col-md-6 text-start text-black fw-bold p-2">
                    Wealth Gain
                  </div>
                  <div class="col-sm-5 col-md-6 text-end text-black fw-bold p-2">
                    ₹{" "}
                    {formatAmountWithComma(
                      calculatedResult.investmentAmount +
                        calculatedResult.interest
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
